import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import colors from "../../assets/scss/config/saas/_colors.scss";
import { MONITOR_STATUS, MONITOR_TYPE } from "../../common/constants";
import { useDispatch, useSelector } from "react-redux";
import { EventBedIcon, EventToiletIcon } from "./SVG";
import { getDisplayDate, getDisplayFloor, getDisplayLocation, getDisplayRoom, getDisplayTime, getDisplayDatetime } from "../../helpers/display_help";
import { createEventMessage, getEvent, getEventMessages, reportStatusChange } from "../../slices/event/thunk";
import { getEventReportReasonOptions, getEventReportReasonOptionValue } from "../../helpers/data_conversion_helper";
import Select from "react-select";
import chroma from "chroma-js";

const getStatus = (value, t) => {
    let boxColor = colors.handledDanger;
    if (value === MONITOR_STATUS.TOILET.FALL || value === MONITOR_STATUS.TOILET.NOMOVE || value === MONITOR_STATUS.BED.FALL || value === MONITOR_STATUS.TOILET.USING_OT) {
        boxColor = colors.handledDanger;
    }
    return (
        <div style={{ backgroundColor: boxColor }} className="dash-event-eventType mr-3 align-self-center">{t('Common.MonitorStatusTypeShortForm.' + value)}</div>
    );
}

const getDeviceStatusIcon = (data) => {
    let iconColor = colors.handledDanger;
    if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
        return <EventToiletIcon size={24} fill={iconColor} />;
    }
    else if (data.eventType === MONITOR_STATUS.BED.FALL) {
        return <EventBedIcon size={24} fill={iconColor} />;
    }
    return null;
}

const getMessageIcon = (data) => {
    return (
        <div className="event-edit-modal-message-icon">
            {data[0].toUpperCase()}
        </div>
    );
}

export const colourStyles = (selectColor) => {
    return {
        menu: (styles, state) => ({
            ...styles,
            backgroundColor: colors.white
        }),
        menuList: (styles, state) => ({
            ...styles,
            backgroundColor: colors.white
        }),
        control: (styles, state) => ({
            ...styles,
            marginLeft: 0,
            marginRight: 6,
            backgroundColor: colors.white,
            border: '1px solid #e5e7eb',
            boxShadow: 'none',
            '&:hover': {
                border: '1px solid #e5e7eb',
            },
            minWidth: 200,
            fontSize: '0.9rem'
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(selectColor);
            return {
                ...styles,
                backgroundColor: color.white,
                color: color,
                fontSize: '0.9rem',
                '&:hover': {
                    backgroundColor: color.alpha(0.1).css()
                },
            };
        }
    }
};

const EventEditModal = ({ show, onCloseClick }) => {
    const { t } = useTranslation();
    const statusChangeID = useSelector((state => state.Event.eventStatusChangeIDEdit));
    const data = useSelector((state => state.DeviceStatus.status[statusChangeID] || state.Event.report.changes[statusChangeID]));
    const eventEditID = data?.eventID;
    const event = useSelector((state => state.Event.events[eventEditID]));
    const user = useSelector((state => state.Profile.user));
    const messages = useSelector((state => state.Event.messages[eventEditID]));
    const eventReportReason = useSelector(state => state.StaticData.eventReportReason);
    const dispatch = useDispatch();
    const [newMessage, setNewMessage] = useState("");
    const [reportReason, setReportReason] = useState({});
    useEffect(() => {        
        if (eventEditID !== null && eventEditID !== undefined) {
            dispatch(getEvent(eventEditID));
            dispatch(getEventMessages(eventEditID));
        }
    }, [statusChangeID]);


    useEffect(() => {        
        if (data !== null) {
            setReportReason(getEventReportReasonOptionValue(data?.reportReasonID, eventReportReason, t));
        }
    }, [data]);

    if (event === undefined) {
        return null;
    }  
    
    return (
        <Modal isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalBody className="d-flex flex-column">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row event-edit-modal-event event-edit-modal-row event-edit-modal-row-with-icon">
                        <div className="event-edit-modal-label">{t('EditEventInfoModal.Title')}:</div>
                        <div className="event-edit-modal-event-status">{getStatus(event.eventType, t)}</div>
                        <div className="d-flex flex-row modal-close-btn">
                            <Button type="button" className="btn-close" onClick={() => { onCloseClick(); }} ></Button>
                        </div>
                    </div>
                    <div className="d-flex flex-row event-edit-modal-location event-edit-modal-row event-edit-modal-row-with-icon">
                        <div className="event-edit-modal-label">{t('EditEventInfoModal.Location')}:</div>
                        <div className="d-flex flex-row event-edit-modal-event-location">
                            <div className="event-edit-modal-row-item">{getDeviceStatusIcon(event)}</div>
                            <div className="event-edit-modal-row-item">{getDisplayFloor(event.floorName, t)}</div>
                            <div className="event-edit-modal-row-item">{getDisplayRoom(event, t)}</div>
                            <div className="event-edit-modal-row-item">{getDisplayLocation(event, t)}</div>
                        </div>
                    </div>
                    <div className="d-flex flex-row event-edit-modal-time event-edit-modal-row">
                        <div className="event-edit-modal-label">{t('EditEventInfoModal.TimeBegin')}:</div>
                        <div className="d-flex flex-row">
                            <div className="event-edit-modal-row-item">{getDisplayDate(event.eventStartTime)}</div>
                            <div className="event-edit-modal-row-item">{getDisplayTime(event.eventStartTime)}</div>
                        </div>
                    </div>
                    <div className="d-flex flex-row event-edit-modal-time event-edit-modal-row">
                        <div className="event-edit-modal-label">{t('EditEventInfoModal.TimeArrived')}:</div>
                        {event.eventArrivedTime === undefined ? <div className="event-edit-modal-row-item">-</div> :
                            <div className="d-flex flex-row">
                                <div className="event-edit-modal-row-item">{getDisplayDate(event.eventArrivedTime)}</div>
                                <div className="event-edit-modal-row-item">{getDisplayTime(event.eventArrivedTime)}</div>
                            </div>}
                    </div>
                    <div className="d-flex flex-row event-edit-modal-time event-edit-modal-row">
                        <div className="event-edit-modal-label">{t('EditEventInfoModal.TimeResolved')}:</div>
                        <div className="d-flex flex-row">
                            <div className="event-edit-modal-row-item">{getDisplayDate(event.eventEndTime)}</div>
                            <div className="event-edit-modal-row-item">{getDisplayTime(event.eventEndTime)}</div>
                        </div>
                    </div>
                    <div className="mt-1 d-flex flex-row event-edit-modal-time event-edit-modal-row align-items-center">
                        <div className="event-edit-modal-label">{t('EditEventInfoModal.ResolvedReason')}:</div>
                        <div className="d-flex flex-row">
                            {eventReportReason !== undefined?
                            <Select
                                value={reportReason}
                                isMulti={false}
                                isClearable={false}
                                placeholder={t('EditEventInfoModal.ResolvedReasonPlaceholder')}
                                onChange={(value) => {
                                    setReportReason(value)
                                }}
                                options={getEventReportReasonOptions(eventReportReason, t)}                                 
                                styles={colourStyles(colors.blue)}
                            />:null}
                        </div>
                        <Button className="event-edit-modal-btn" color="primary" disabled={data.reportReasonID === reportReason?.value} onClick={()=>{
                            if (reportReason !== null) {
                                dispatch(reportStatusChange(statusChangeID, reportReason.value)).then(()=>{
                                    onCloseClick();
                                });
                            }
                        }}> {t('EditEventInfoModal.BtnSave')} </Button>
                    </div>
                    <div className="d-flex flex-row event-edit-modal-message event-edit-modal-row">
                        <div className="event-edit-modal-label">{t('EditEventInfoModal.Detail')}</div>
                    </div>
                    <div className="d-flex flex-column event-edit-modal-row event-edit-modal-message-list-container">
                    {messages !== undefined && messages.map(m => {
                        return (
                            <div key={m.id} className="d-flex flex-row event-edit-modal-message">
                                <div className="event-edit-modal-label-message">{getMessageIcon(m.username)}</div>
                                <div className="d-flex flex-column">
                                    <div className="event-edit-modal-message-list-main">{m.content}</div>
                                    <div className="event-edit-modal-message-list-sub">{m.username + " " + getDisplayDatetime(m.updated_at)}</div>
                                </div>
                            </div>
                        );
                    })}
                    </div>
                    <div className="d-flex flex-row event-edit-modal-message">
                        <div className="event-edit-modal-label-message">{getMessageIcon(user.username)}</div>
                        <div className="d-flex flex-column event-edit-modal-label-message-edit">
                            <textarea className="form-control event-edit-modal-label-message-edit-area" id="messageTextArea" rows="3" placeholder={t('EditEventInfoModal.MessagePlaceholder')} value={newMessage} onChange={(event)=>{setNewMessage(event.target.value)}}></textarea>
                            <div className="d-flex flex-row event-edit-modal-row  justify-content-end">
                                <Button className="btn-soft-primary btn-sm event-edit-modal-message-btn" color="primary" onClick={()=>{
                                    if (newMessage.length > 0) {
                                        dispatch(createEventMessage(event.id, newMessage)).then(()=>{
                                            setNewMessage("");
                                        });
                                    }
                                }}> {t('EditEventInfoModal.BtnSave')} </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}

EventEditModal.propTypes = {
    onCloseClick: PropTypes.func,
    data: PropTypes.any,
    show: PropTypes.any,
};

export default EventEditModal;