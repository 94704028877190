import React, { useState } from "react";
import { Card, CardBody, CardHeader} from "reactstrap";
import { useSelector } from "react-redux";
import ColumnChart from "./ColumnChart";
import { REPORT_PERIOD } from "../../common/constants";
import { useTranslation } from "react-i18next";

function ToiletFallSummaryChart() {
    const { t } = useTranslation();
    const [toiletFallSummaryPeriod, setToiletFallSummaryPeriod] = useState(REPORT_PERIOD.ALL);
    const toiletFallSummaryData = useSelector(state => toiletFallSummaryPeriod === REPORT_PERIOD.M1?state.Summary.toiletFall.M1:toiletFallSummaryPeriod === REPORT_PERIOD.M3?state.Summary.toiletFall.M3:toiletFallSummaryPeriod === REPORT_PERIOD.M6?state.Summary.toiletFall.M6:state.Summary.toiletFall.ALL);
    const xFont = (toiletFallSummaryData.form === REPORT_PERIOD.M1 || toiletFallSummaryData.form === REPORT_PERIOD.M3)?'10px':'12px';
    return (
        <React.Fragment>
            <Card className="summary-card">
                <CardHeader className="summary-card-header">
                    <h4 className="card-title mb-0 summary-title">{t('PageSummary.ToiletFall.Title')}</h4>
                    <div className="d-flex flex-row align-items-center">
                        <div className={toiletFallSummaryPeriod === REPORT_PERIOD.M1 ? "summary-period-box summary-period-box-selected" : "summary-period-box"} onClick={() => {
                            setToiletFallSummaryPeriod(REPORT_PERIOD.M1)
                        }}>{t("PageSummary.Period.1M")}</div>
                        <div className={toiletFallSummaryPeriod === REPORT_PERIOD.M3 ? "summary-period-box summary-period-box-selected" : "summary-period-box"} onClick={() => {
                            setToiletFallSummaryPeriod(REPORT_PERIOD.M3)
                        }}>{t("PageSummary.Period.3M")}</div>
                        <div className={toiletFallSummaryPeriod === REPORT_PERIOD.M6 ? "summary-period-box summary-period-box-selected" : "summary-period-box"} onClick={() => {
                            setToiletFallSummaryPeriod(REPORT_PERIOD.M6)
                        }}>{t("PageSummary.Period.6M")}</div>
                        <div className={toiletFallSummaryPeriod === REPORT_PERIOD.ALL ? "summary-period-box summary-period-box-selected" : "summary-period-box"} onClick={() => {
                            setToiletFallSummaryPeriod(REPORT_PERIOD.ALL)
                        }}>{t("PageSummary.Period.ALL")}</div>
                    </div>
                </CardHeader>
                <CardBody>
                    <ColumnChart dataColors='["--vz-danger"]' 
                    yData={[{data: toiletFallSummaryData.y, name: t('PageSummary.ToiletFall.Subtitle')}]}
                    xData={toiletFallSummaryData.x} xFont={xFont} />
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default ToiletFallSummaryChart;
