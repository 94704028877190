import React, { useState } from "react";
import { Card, CardBody, CardHeader} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import ColumnChart from "./ColumnChart";
import { REPORT_PERIOD } from "../../common/constants";
import { useTranslation } from "react-i18next";
import { getDisplayLocation } from "../../helpers/display_help";

function ToiletUsingFrequenceSummaryChart(props) {
    const { t } = useTranslation();
    const toiletSummaryPeriod = useSelector(state => state.Summary.toiletUsingFrequence.form);
    const toiletSummaryData = useSelector(state => state.Summary.toiletUsingFrequence);
    const xFont = (toiletSummaryData.form === REPORT_PERIOD.M1 || toiletSummaryData.form === REPORT_PERIOD.M3)?'10px':'12px';
    let series = [];
    toiletSummaryData.devices.forEach(deviceID => {
        let data = toiletSummaryData.deviceInfo[deviceID];
        let location = getDisplayLocation(data, t);
        series.push({
            name: location,
            data: toiletSummaryData.freqInfo[deviceID]
        })
    });

    return (
        <React.Fragment>
            <Card className="summary-card">
                <CardHeader className="summary-card-header">
                    <h4 className="card-title mb-0 summary-title">{t('PageSummary.ToiletUsingFrequence.Title')}</h4>
                    <div className="d-flex flex-row align-items-center">
                        <div className={toiletSummaryPeriod === REPORT_PERIOD.M1 ? "summary-period-box summary-period-box-selected" : "summary-period-box"} onClick={() => {
                            props.setToiletSummaryPeriod(REPORT_PERIOD.M1)
                        }}>{t("PageSummary.Period.1M")}</div>
                        <div className={toiletSummaryPeriod === REPORT_PERIOD.M3 ? "summary-period-box summary-period-box-selected" : "summary-period-box"} onClick={() => {
                            props.setToiletSummaryPeriod(REPORT_PERIOD.M3)
                        }}>{t("PageSummary.Period.3M")}</div>
                        <div className={toiletSummaryPeriod === REPORT_PERIOD.M6 ? "summary-period-box summary-period-box-selected" : "summary-period-box"} onClick={() => {
                            props.setToiletSummaryPeriod(REPORT_PERIOD.M6)
                        }}>{t("PageSummary.Period.6M")}</div>
                        <div className={toiletSummaryPeriod === REPORT_PERIOD.ALL ? "summary-period-box summary-period-box-selected" : "summary-period-box"} onClick={() => {
                            props.setToiletSummaryPeriod(REPORT_PERIOD.ALL)
                        }}>{t("PageSummary.Period.ALL")}</div>
                    </div>
                </CardHeader>
                <CardBody>
                    <ColumnChart dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info", "--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]' yData={series} xData={toiletSummaryData.category} xFont={xFont} />
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default ToiletUsingFrequenceSummaryChart;
