import React, { useEffect, useState } from "react";
import { getDeviceConfig } from "../../services/developer";
import DeviceConfigRow from "./DeviceConfigRow";

function DeviceConfig(props) {
    const { device, lastUpdateDate } = props;
    const [config, setConfig] = useState([]);
    useEffect(() => {
        if (device !== undefined && device["id"] !== undefined) {
            getDeviceConfig(device["id"]).then(result => {
                if (result.err === null) {
                    setConfig(result.data);
                }
            });
        }
    }, [device, lastUpdateDate]);

    if (config?.length > 0) {
        return (
            <div className="page-developer-device-details">
                {config?.map(data => {
                    return <DeviceConfigRow key={data["id"]} data={data} deviceID={device["id"]} />;
                })}
            </div>
        );
    }
    else {
        return null;
    }
}

export default DeviceConfig;