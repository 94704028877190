import { getSummaryBedArrivalService, getSummaryBedFallService, getSummaryToiletArrivalService, getSummaryToiletFallService, getSummaryToiletOTService, getSummaryToiletUsingAverageTimeService, getSummaryToiletUsingFrequenceService } from "../../services/summary";
import { apiError, getBedArrivalSummarySuccess, getBedFallSummarySuccess, getToiletArrivalSummarySuccess, getToiletFallSummarySuccess, getToiletOTSummarySuccess, getToiletUsingAverageTimeSummarySuccess, getToiletUsingFrequenceSummarySuccess } from "./reducer";

export const getSummaryToiletFall = (selectedMultiFloorPlan, dateRange) => async (dispatch) => {
  try {    
    var data = await getSummaryToiletFallService(selectedMultiFloorPlan, dateRange);
    console.log(data);
    dispatch(getToiletFallSummarySuccess(data));
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};

export const getSummaryToiletOT = (selectedMultiFloorPlan, dateRange) => async (dispatch) => {
  try {    
    var data = await getSummaryToiletOTService(selectedMultiFloorPlan, dateRange);
    console.log(data);
    dispatch(getToiletOTSummarySuccess(data));
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};

export const getSummaryToiletArrival = (selectedMultiFloorPlan, dateRange) => async (dispatch) => {
  try {    
    var data = await getSummaryToiletArrivalService(selectedMultiFloorPlan, dateRange);
    console.log(data);
    dispatch(getToiletArrivalSummarySuccess(data));
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};

export const getSummaryBedFall = (selectedMultiFloorPlan, dateRange) => async (dispatch) => {
  try {    
    var data = await getSummaryBedFallService(selectedMultiFloorPlan, dateRange);
    console.log(data);
    dispatch(getBedFallSummarySuccess(data));
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};

export const getSummaryBedArrival = (selectedMultiFloorPlan, dateRange) => async (dispatch) => {
  try {    
    var data = await getSummaryBedArrivalService(selectedMultiFloorPlan, dateRange);
    console.log(data);
    dispatch(getBedArrivalSummarySuccess(data));
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};

export const getSummaryToiletUsingAverageTime = (selectedMultiFloorPlan, dateRange) => async (dispatch) => {
  try {    
    var data = await getSummaryToiletUsingAverageTimeService(selectedMultiFloorPlan, dateRange);
    console.log(data);
    dispatch(getToiletUsingAverageTimeSummarySuccess(data));
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};

export const getSummaryToiletUsingFrequence = (selectedMultiFloorPlan, dateRange, form = "default") => async (dispatch) => {
  try {    
    var data = await getSummaryToiletUsingFrequenceService(selectedMultiFloorPlan, dateRange, form);
    console.log(data);
    dispatch(getToiletUsingFrequenceSummarySuccess(data));
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};