import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'reactstrap';
import { updateDeviceConfig } from '../../services/device';

function isValidURL(url) {
    // Regular expression for URL validation
    const urlRegex = /^(?:https?|ftp):\/\/(?:\S+(?::\S*)?@)?(?:(?!-)[-A-Za-z0-9]+\.)+[A-Za-z]{2,}(?::\d{1,5})?(?:[/?#]\S*)?$/;

    return urlRegex.test(url);
}

function isNumber(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
}

function DeviceConfigRow(props) {
    const { data, deviceID } = props;
    const { t } = useTranslation();
    const [isEdited, setEdited] = useState(false);
    const [value, setValue] = useState(data.value);
    const [settingID, setSettingID] = useState(data.id);
    const [isShowError, setShowError] = useState(false);

    const handleInputChange = (event) => {
        if (isShowError) {
            setShowError(false);
        }
        setValue(event.target.value);
    };

    return (
        <div className='d-flex flex-row align-items-center'>
            <div className="input-group d-flex flex-row">
                <span className="input-group-text col-4" id="inputGroup-sizing-default">{data.name}</span>
                <Input type={data.name === "THIRD_PARTY_API" ? "text" : "number"} className="form-control col-6" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" value={value} onChange={handleInputChange} disabled={!isEdited} />
                {isEdited ? <Button className="btn-success" onClick={() => {
                    let isValid = true;
                    if (data.name === "THIRD_PARTY_API") {
                        isValid = isValidURL(value) || (value === "");
                    }
                    else {
                        isValid = isNumber(value);
                    }
                    if (isValid) {
                        updateDeviceConfig(deviceID, data.configid, settingID, value).then(result => {
                            console.log("updateDeviceConfig !!!");
                            console.log(result);
                            if (result.data.result) {
                                setSettingID(result.data.newSettingID);
                                setEdited(false);
                                setShowError(false);
                            }
                            else {
                                setShowError(true);
                            }
                        })
                    }
                    else {
                        setShowError(true);
                    }
                }}>{t('Common.Btn.Save')}</Button> : <Button onClick={() => { setEdited(true) }}>{t('Common.Btn.Modify')}</Button>}
            </div>
            {isShowError ? <div className='text-danger error-msg'>{t('PageDeveloper.Config.Error')}</div> : null}
        </div>
    )
}

export default DeviceConfigRow;