import { ALL_LOCATIONS, ALL_MONITOR_STATUS, ALL_MONITOR_TYPES, ALL_MONITOR_TYPE_STATUS, getAllEventHandlingOption, getAllFloorOption, getAllStatusOption, getAllTypeOption } from '../Components/Common/DeviceFilter';
import { LOCATION_TYPE } from '../common/constants';

export const getFloorPlanData = (data, t) => {
    let result = [getAllFloorOption(t)];

    data.forEach(value => {
        let options = [getFloorName(value, t)];
        value['locations'].forEach(inner => {
            options.push(getLocationName(value, inner, t));
        });
        result.push({ label: value.name + t('Common.FloorConnector') + t('Common.FloorShortForm'), options: options });
    });
    return result;
};

export const getFloorName = (value, t) => {
    return { label: value.name + t('Common.FloorConnector') + t('Common.FloorShortForm') + t('Common.Space1') + t('Common.LocationAll'), value: ALL_LOCATIONS + '_' + value.id, floor: value.id.toString() };
};

export const getLocationName = (floor, location, t) => {
    if (location.type === LOCATION_TYPE.ROOM) {
        return { label: floor.name + t('Common.FloorConnector') + t('Common.FloorShortForm') + t('Common.Space1') + t('Common.RoomNumber') + t('Common.Space1') + location.name + t('Common.Space1') + t('Common.RoomUnit'), value: location.id.toString(), floor: floor.id.toString() };
    } else {
        return { label: floor.name + t('Common.FloorConnector') + t('Common.FloorShortForm') + t('Common.Space1') + t('Common.ToiletNumber') + t('Common.Space1') + location.name + t('Common.Space1') + t('Common.ToiletUnit'), value: location.id.toString(), floor: floor.id.toString() };
    }
};

export const getMonitorTypeData = (data, t) => {
    let result = [getAllTypeOption(t)];
    data.forEach(value => {
        result.push(getMonitorTypeName(value, t));
    });

    return result;
};

export const getMonitorTypeName = (value, t) => {
    return { label: t('Common.MonitorType.' + value.id), value: value.id.toString() };
};

export const getMonitorStatusData = (data, t) => {
    let result = [getAllStatusOption(t)];
    for (const statusType in data) {
        let value = data[statusType];
        let options = [getStatusTypeName(statusType, t)];
        value.forEach(status => {
            options.push(getStatusName(statusType, status, t));
        });

        result.push({ label: t('Common.MonitorType.' + statusType), options: options });
    }

    return result;
};

export const getEventHanldingData = (data, t) => {
    if (data === undefined || Object.keys(data).length === 0) {
        return [];
    }
    let result = [getAllEventHandlingOption(t), ...getEventReportReasonOptions(data, t)];

    return result;
};

export const getEventHanldingName = (value, t) => {
    return { label: t('Common.EventReportReason.' + value.value), value: value.id.toString() };
};

export const getStatusTypeName = (statusType, t) => {
    return { label: t('Common.All') + t('Common.Space') + t('Common.MonitorType.' + statusType) + t('Common.Space') + t('Common.Status'), value: ALL_MONITOR_TYPE_STATUS + '_' + statusType, status: statusType };
};

export const getStatusName = (statusType, status, t) => {
    return { label: t('Common.MonitorStatusType.' + status), value: status.toString(), status: statusType };
};

export const getEventHandlingName = (value, t) => {
    return { label: t('Common.Common.' + value), value: value.id.toString() };
};

export const getCareTypeData = (data, t) => {
    let result = [];
    data.forEach(value => {
        result.push({ label: t('Common.CareType.' + value.value), value: value.id });
    });

    return result;
};

export const getAppliedRulesData = (data, t) => {
    let result = [];
    data.forEach(value => {
        result.push({ label: t('Common.AppliedRuleType.' + value.value), value: value.id });
    });

    return result;
};

export const getEventReportReasonOptions = (data, t) => {
    let result = [];
    data['seq'].forEach(value => {
        let d = data['data'][value];
        result.push({ label: t('Common.EventReportReason.' + d), value: value });
    });

    console.log(result);
    return result;
};

export const getEventReportReasonOptionValue = (reason, options, t) => {
    let result = null;
    if (options === undefined || reason === undefined) {
        return result;
    }
    options.seq?.forEach(value => {
        if (value === reason) {
            let d = options['data'][value];
            result = { label: t('Common.EventReportReason.' + d), value: value };
        }
    });

    return result;
};

export const getEventReportReasonValue = (reason, options, t) => {
    let result = null;
    if (options === undefined || reason === undefined) {
        return result;
    }
    options.seq?.forEach(value => {
        if (value === reason) {
            result = t('Common.EventReportReason.' + options['data'][value]);
        }
    });

    return result;
};

export const getAllEventReportReasonValues = (changeIDs, changes, options, t) => {
    let result = null;
    if (changeIDs === null) {
        return result;
    }

    if (options === undefined) {
        return result;
    }

    let uniqueChangeIDList = [];
    changeIDs.forEach(c => {
        let change = changes[c];
        let reportReason = options['data'][change?.reportReasonID];
        if (reportReason !== null && reportReason !== undefined && !uniqueChangeIDList.includes(reportReason)) {
            uniqueChangeIDList.push(reportReason);
            let name = t('Common.EventReportReason.' + reportReason);
            if (result === null) {
                result = name;
            } else {
                result = result + ', ' + name;
            }
        }
    });

    return result;
};
