import { useTranslation } from "react-i18next";
import { getDisplayDate, getDisplayDatetime, getDisplayDatetimeFromTimestamp, getDisplayFloor, getDisplayLocation, getDisplayRoom, getDisplayTime } from "../../helpers/display_help";
import { MONITOR_STATUS, MONITOR_TYPE } from "../../common/constants";
import colors from "../../assets/scss/config/saas/_colors.scss";
import { useSelector } from "react-redux";
import { Fragment, useState } from "react";
import DeviceConfig from "./DeviceConfig";

const getStatus = (value, t) => {
    let boxColor = colors.handledDanger;
    if (value === MONITOR_STATUS.TOILET.FALL || value === MONITOR_STATUS.BED.FALL || value === MONITOR_STATUS.TOILET.USING_OT) {
        boxColor = colors.handledDanger;
    }
    else if (value === MONITOR_STATUS.TOILET.USING) {
        boxColor = colors.handledUsing;
    }
    else if (value === MONITOR_STATUS.TOILET.IDLE) {
        boxColor = colors.handledIdle;
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ backgroundColor: boxColor }} className="report-event-eventType mr-3 align-self-center">{t('Common.MonitorStatusTypeShortForm.' + value)}</div>
        </div>
    );
}

const getDescription = (data, isMQConnected, isSocketConnected, t) => {
    if (data.isConnected && isMQConnected && isSocketConnected) {
        if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
            if (data.currentMonitorStatus === null) {
                return <div className="dash-device-card-text-toilet-idle">-</div>;
            }
            else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.IDLE) {
                return <div className="dash-device-card-text-toilet-idle">{t('Common.MonitorStatusTypeShortForm.' + data.currentMonitorStatus)}</div>;
            }
            else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING) {
                return <div className="dash-device-card-text-toilet-using">{t('Common.MonitorStatusTypeShortForm.' + data.currentMonitorStatus)}</div>;
            }
            else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT || data.currentMonitorStatus === MONITOR_STATUS.TOILET.NOMOVE || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FALL) {
                return <div className="dash-danger-status-box dash-danger-status-box-text">{t('Common.MonitorStatusTypeShortForm.' + data.currentMonitorStatus)}</div>;
            }
            else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_USING_OT || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_NOMOVE || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_FALL) {
                return <div className="dash-device-card-text-toilet-danger">{t('Common.MonitorStatusTypeShortForm.' + data.currentMonitorStatus)}</div>;
            }
            else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.NO_STATUS) {
                return <div className="dash-device-card-text-off">{t('Common.Updating')}</div>;
            }
            else {
                return <div></div>
            }
        }
        else {
            if (data.currentMonitorStatus === null) {
                return <div className="dash-device-card-text-toilet-idle">-</div>;
            }
            else if (data.currentMonitorStatus === MONITOR_STATUS.BED.IDLE) {
                return <div className="dash-device-card-text-toilet-idle">{t('Common.MonitorStatusTypeShortForm.' + data.currentMonitorStatus)}</div>;
            }
            else if (data.currentMonitorStatus === MONITOR_STATUS.BED.NO_STATUS) {
                return <div className="dash-device-card-text-off">{t('Common.Updating')}</div>;
            }
            else {
                return <div></div>
            }
        }
    }
    else {
        return <div className="dash-device-card-text-nosignal">{t('Common.Connection.Off')}</div>
    }
}

function DeviceRow(props) {
    const { t } = useTranslation();
    const [isCollapse, setCollapse] = useState(true);
    const data = props.data;
    const isMQConnected = useSelector(state => state.Device.isMQConnected);
    const isSocketConnected = useSelector(state => state.Device.isSocketConnected);

    return (
        <Fragment>
            <tr onClick={() => { setCollapse(!isCollapse); }}>
                <td className="fw-medium collapse-icon">
                    {isCollapse ? <i className="bx bxs-plus-square"></i> : <i className="bx bxs-minus-square"></i>}
                </td>
                <td className="fw-medium">{data["id"]}</td>
                <td>{data["companyName"]}</td>
                <td>{getDisplayFloor(data["floorName"], t)}</td>
                <td>{getDisplayRoom(data, t)}</td>
                <td>{getDisplayLocation(data, t)}</td>
                <td>{getDescription(data, isMQConnected, isSocketConnected, t)}</td>
                <td>{getDisplayDatetime(data["statusStarttime"])}</td>
            </tr>
            {isCollapse ? null :
                <tr>
                    <td></td>
                    <td colSpan="10">
                        <DeviceConfig device={data} lastUpdateDate={props.lastUpdateDate} />
                    </td>
                </tr>}
        </Fragment>
    );
}

export default DeviceRow;