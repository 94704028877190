import { createSlice } from '@reduxjs/toolkit';
import { MONITOR_MESSAGE_SOURCE } from '../../helpers/display_help';

export const initialState = {
    status: {},
    id: [],
    error: '',
    dashboardReply: {},
    opuschatReply: {},
    mqttReply: {}
};

const deviceStatusSlice = createSlice({
    name: 'DeviceStatus',
    initialState,
    reducers: {
        apiError(state, action) {
            return {
                ...state,
                error: action.payload
            };
        },
        getDeviceStatusListSuccess(state, action) {
            console.log(action);
            if (Array.isArray(action.payload.statusID)) {
                return {
                    ...state,
                    status: { ...action.payload.status },
                    id: [...action.payload.statusID],
                    dashboardReply: { ...action.payload.dashboard },
                    opuschatReply: { ...action.payload.opuschat },
                    mqttReply: { ...action.payload.mqtt }
                };
            } else {
                return state;
            }
        },
        updateDeviceStatus(state, action) {
            if (Array.isArray(action.payload.statusID)) {
                let newID = [];
                let dashboardReply = { ...state.dashboardReply };
                let opuschatReply = { ...state.opuschatReply };
                let mqttReply = { ...state.mqttReply };
                action.payload.statusID.forEach(item => {
                    if (state.status !== undefined && state.status[item] === undefined) {
                        newID.push(item);
                    }
                    if (action.payload.dashboard[item] !== undefined) {
                        dashboardReply = { ...dashboardReply, [item]: action.payload.dashboard[item] };
                    }
                    if (action.payload.opuschat[item] !== undefined) {
                        opuschatReply = { ...opuschatReply, [item]: action.payload.opuschat[item] };
                    }
                    if (action.payload.mqtt[item] !== undefined) {
                        mqttReply = { ...mqttReply, [item]: action.payload.mqtt[item] };
                    }
                });
                return {
                    ...state,
                    status: { ...state.status, ...action.payload.status },
                    id: [...newID, ...state.id],
                    dashboardReply: dashboardReply,
                    opuschatReply: opuschatReply,
                    mqttReply: mqttReply
                };
            } else {
                return state;
            }
        },
        updateStatusChangeReportSuccess(state, action) {
            let status = state.status[action.payload.statusChangeID];

            console.log('action.payload.statusChangeID: ', action.payload.statusChangeID);
            console.log('status: ', status);

            if (status === null || status === undefined) {
                return state;
            }

            let newStatus = { ...status, reportReasonID: action.payload.reason };
            console.log('newStatus: ', newStatus);
            return {
                ...state,
                status: {
                    ...state.status,
                    [action.payload.statusChangeID]: newStatus
                }
            };
        },
        addStatusChangeReply(state, action) {
            let status = state.status[action.payload.statusChangeRecordID];

            if (status === null || status === undefined) {
                return state;
            }

            if (action.payload.source === MONITOR_MESSAGE_SOURCE.DASHBOARD) {
                let list = state.dashboardReply[action.payload.statusChangeRecordID] !== undefined ? [...state.dashboardReply[action.payload.statusChangeRecordID]] : [];
                list.push(action.payload.data);
                return {
                    ...state,
                    dashboardReply: {
                        ...state.dashboardReply,
                        [action.payload.statusChangeRecordID]: list
                    }
                };
            } else if (action.payload.source === MONITOR_MESSAGE_SOURCE.OPUSCHAT) {
                let list = state.opuschatReply[action.payload.statusChangeRecordID] !== undefined ? [...state.opuschatReply[action.payload.statusChangeRecordID]] : [];
                list.push(action.payload.data);
                return {
                    ...state,
                    opuschatReply: {
                        ...state.opuschatReply,
                        [action.payload.statusChangeRecordID]: list
                    }
                };
            }
            return state;
        },
        resetState: () => initialState
    }
});

export const { apiError, getDeviceStatusListSuccess, updateDeviceStatus, updateStatusChangeReportSuccess, addStatusChangeReply, resetState } = deviceStatusSlice.actions;

export default deviceStatusSlice.reducer;
