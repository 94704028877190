import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { formatManualReplyData, getDisplayDate, getDisplayDatetime, getDisplayDatetimeFromTimestamp, getDisplayFloor, getDisplayLocation, getDisplayRoom, getDisplayTime, isShowEventEditBtn } from '../../helpers/display_help';
import { useDispatch, useSelector } from 'react-redux';
import { MONITOR_STATUS } from '../../common/constants';
import colors from '../../assets/scss/config/saas/_colors.scss';
import { Fragment, useState } from 'react';
import { EditIcon } from '../../Components/Common/SVG';
import { setEventStatusChangeIDEdit } from '../../slices/event/reducer';
import { getAllEventReportReasonValues, getEventReportReasonValue } from '../../helpers/data_conversion_helper';

const getStatus = (value, t) => {
    let boxColor = colors.handledDanger;
    if (value === MONITOR_STATUS.TOILET.FALL || value === MONITOR_STATUS.BED.FALL || value === MONITOR_STATUS.TOILET.USING_OT || value === MONITOR_STATUS.TOILET.NOMOVE) {
        boxColor = colors.handledDanger;
    } else if (value === MONITOR_STATUS.TOILET.FROZEN_NOMOVE || value === MONITOR_STATUS.TOILET.FROZEN_FALL || value === MONITOR_STATUS.TOILET.FROZEN_USING_OT) {
        boxColor = colors.handledUser;
    } else if (value === MONITOR_STATUS.TOILET.USING || value === MONITOR_STATUS.TOILET.USING_OT_PAUSED) {
        boxColor = colors.handledUsing;
    } else if (value === MONITOR_STATUS.TOILET.IDLE) {
        boxColor = colors.handledIdle;
    } else {
        boxColor = colors.handledUser;
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ backgroundColor: boxColor }} className="report-event-eventType mr-3 align-self-center">
                {t('Common.MonitorStatusTypeReportForm.' + value)}
            </div>
        </div>
    );
};

function ReportRow(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const eventID = props.eventID;
    const data = useSelector(state => state.Event.report.events[eventID]);
    const changes = useSelector(state => state.Event.report.changes);
    const [isCollapse, setCollapse] = useState(true);
    const staticEventReportReason = useSelector(state => state.StaticData.eventReportReason);

    const handleClickEdit = changeID => {
        console.log('handleClickEdit changeID: ' + changeID);
        dispatch(setEventStatusChangeIDEdit(changeID));
        props.setOpenEditEventModal(true);
    };

    if (data.isShow === false) {
        return null;
    }

    return (
        <Fragment>
            <tr
                onClick={() => {
                    setCollapse(!isCollapse);
                }}>
                <td className="fw-medium collapse-icon">{data['changeID'].length === 0 || data['eventType'] === MONITOR_STATUS.TOILET.USING || data['eventType'] === MONITOR_STATUS.TOILET.IDLE ? null : isCollapse ? <i className="bx bxs-plus-square"></i> : <i className="bx bxs-minus-square"></i>}</td>
                <td className="fw-medium">{data['id']}</td>
                <td>{getDisplayDate(data['startTime'])}</td>
                <td>{getDisplayTime(data['startTime'])}</td>
                <td>{getDisplayFloor(data['floorName'], t)}</td>
                <td>{getDisplayRoom(data, t)}</td>
                <td>{getDisplayLocation(data, t)}</td>
                <td>{getStatus(data['eventType'], t)}</td>
                <td>{getAllEventReportReasonValues(data['changeID'], changes, staticEventReportReason, t)}</td>
                <td>{data['endTime'] === 0 ? '-' : getDisplayDatetimeFromTimestamp(data['endTime'] - data['startTime'])}</td>
                <td>{data['arriveTime'] ? getDisplayDatetime(data['arriveTime']) : '-'}</td>
            </tr>
            {isCollapse || data['eventType'] === MONITOR_STATUS.TOILET.USING || data['eventType'] === MONITOR_STATUS.TOILET.IDLE ? null : (
                <tr>
                    <td></td>
                    <td colSpan="9">
                        <Table className="table-nowrap mb-0">
                            <thead className="table-light">
                                <tr>
                                    <th scope="col">{t('PageReport.Table.Change.StartDate')}</th>
                                    <th scope="col">{t('PageReport.Table.Change.StartTime')}</th>
                                    <th scope="col">{t('PageReport.Table.Change.Event')}</th>
                                    <th scope="col">{t('PageReport.Table.Change.ReportReason')}</th>
                                    <th scope="col">{t('PageReport.Table.Change.Duration')}</th>
                                    <th scope="col">{t('PageReport.Table.Change.DashboardHandleSystem')}</th>
                                    <th scope="col">{t('PageReport.Table.Change.OpusChatHandleSystem')}</th>
                                    <th scope="col">{t('PageReport.Table.Change.PhysicalBtnHandleSystem')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data['changeID'].map(i => {
                                    let change = changes[i];
                                    return (
                                        <tr key={change['startTime']}>
                                            <td className="fw-medium">{getDisplayDate(change['startTime'])}</td>
                                            <td>{getDisplayTime(change['startTime'])}</td>
                                            <td>{getStatus(change['status'], t)}</td>
                                            <td name="editEventCell">
                                                {change['reportReasonID'] !== undefined && change['reportReasonID'] !== null ? (
                                                    <div
                                                        onClick={() => {
                                                            handleClickEdit(i);
                                                        }}>
                                                        {getEventReportReasonValue(change['reportReasonID'], staticEventReportReason, t)}
                                                        {isShowEventEditBtn(change['status']) ? (
                                                            <span className="ps-1">
                                                                <EditIcon scale={0.9} fill={colors.handledResolved} />
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td>{change['endTime'] === 0 ? '-' : getDisplayDatetimeFromTimestamp(change['endTime'] - change['startTime'])}</td>
                                            <td style={{ maxWidth: 100, overflow: 'hidden' }}>
                                                <div style={{ display: 'inline' }} className="text-truncate">
                                                    {formatManualReplyData(t('Common.MessageSource.bnet-dashboard'), change['dashboardReply'], t)}
                                                </div>
                                            </td>
                                            <td style={{ maxWidth: 100, overflow: 'hidden' }}>
                                                <div style={{ display: 'inline' }} className="text-truncate">
                                                    {formatManualReplyData(t('Common.MessageSource.bnet-opuschat'), change['opuschatReply'], t)}
                                                </div>
                                            </td>
                                            <td style={{ maxWidth: 100, overflow: 'hidden' }}>
                                                <div style={{ display: 'inline' }} className="text-truncate">
                                                    {change['mqttReply'] !== undefined && change['mqttReply'] !== 0 ? t('Common.PhysicalBtnPressBtn') : ''}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </td>
                </tr>
            )}
        </Fragment>
    );
}

export default ReportRow;
