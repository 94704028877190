import { Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { formatManualReplyData, getDisplayDate, getDisplayDatetimeFromTimestamp, getDisplayFloor, getDisplayLocation, getDisplayRoom, getDisplayTime, isShowEventEditBtn } from '../../helpers/display_help';
import { useDispatch, useSelector } from 'react-redux';
import { MONITOR_STATUS, MONITOR_TYPE } from '../../common/constants';
import colors from '../../assets/scss/config/saas/_colors.scss';
import { EditIcon, EventBedIcon, EventToiletIcon } from '../../Components/Common/SVG';
import { setEventStatusChangeIDEdit } from '../../slices/event/reducer';
import { Fragment } from 'react';
import { getEventReportReasonValue } from '../../helpers/data_conversion_helper';

const getStatus = (value, t) => {
    let boxColor = colors.handledDanger;
    let text = t('Common.MonitorStatusTypeReportForm.' + value);
    if (value === MONITOR_STATUS.TOILET.FALL || value === MONITOR_STATUS.BED.FALL || value === MONITOR_STATUS.TOILET.NOMOVE || value === MONITOR_STATUS.TOILET.USING_OT) {
        boxColor = colors.handledDanger;
    } else if (value === MONITOR_STATUS.TOILET.FROZEN_NOMOVE || value === MONITOR_STATUS.TOILET.FROZEN_FALL || value === MONITOR_STATUS.TOILET.FROZEN_USING_OT) {
        boxColor = colors.handledUser;
    } else if (value === MONITOR_STATUS.TOILET.USING || value === MONITOR_STATUS.TOILET.USING_OT_PAUSED) {
        boxColor = colors.handledUsing;
    } else if (value === MONITOR_STATUS.TOILET.IDLE) {
        boxColor = colors.handledIdle;
    } else if (value === MONITOR_STATUS.BED.NO_STATUS || value === MONITOR_STATUS.TOILET.NO_STATUS) {
        boxColor = colors.handledUser;
    }

    return (
        <div style={{ backgroundColor: boxColor, marginRight: 8 }} className="dash-event-eventType align-self-center">
            {text}
        </div>
    );
};

const getDeviceStatusIcon = data => {
    let iconColor = colors.handledDanger;
    let value = data.monitorStatus;
    if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
        if (value === MONITOR_STATUS.TOILET.FALL || value === MONITOR_STATUS.TOILET.NOMOVE || value === MONITOR_STATUS.TOILET.USING_OT) {
            iconColor = colors.handledDanger;
        } else if (value === MONITOR_STATUS.TOILET.FROZEN_NOMOVE || value === MONITOR_STATUS.TOILET.FROZEN_FALL || value === MONITOR_STATUS.TOILET.FROZEN_USING_OT) {
            iconColor = colors.handledUser;
        } else if (value === MONITOR_STATUS.TOILET.USING || value === MONITOR_STATUS.TOILET.USING_OT_PAUSED) {
            iconColor = colors.handledUsing;
        } else if (value === MONITOR_STATUS.TOILET.IDLE) {
            iconColor = colors.handledIdle;
        } else if (value === MONITOR_STATUS.BED.NO_STATUS || value === MONITOR_STATUS.TOILET.NO_STATUS) {
            iconColor = colors.handledUser;
        }

        return <EventToiletIcon scale={1.2} fill={iconColor} />;
    } else {
        if (data.eventType === MONITOR_STATUS.BED.FALL) {
            iconColor = colors.handledDanger;
        }

        return <EventBedIcon scale={1.2} fill={iconColor} />;
    }
};

const getDuration = (startTime, endTime) => {
    if (endTime === 0) {
        return 'Now';
    } else {
        return getDisplayDatetimeFromTimestamp(endTime - startTime);
    }
};

function HistoryCard(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const statusID = props.data;
    const data = useSelector(state => state.DeviceStatus.status[statusID]);
    const staticEventReportReason = useSelector(state => state.StaticData.eventReportReason);
    const opuschatReply = useSelector(state => state.DeviceStatus.opuschatReply[statusID]);
    const dashboardReply = useSelector(state => state.DeviceStatus.dashboardReply[statusID]);
    const mqttReply = useSelector(state => state.DeviceStatus.mqttReply[statusID]);

    return (
        <Card
            className="dash-event-card"
            onClick={() => {
                if (isShowEventEditBtn(data.monitorStatus)) {
                    dispatch(setEventStatusChangeIDEdit(statusID));
                    props.setOpenEditEventModal(true);
                }
            }}>
            <CardBody className="dash-event-container">
                <div className="d-flex flex-row flex-wrap">
                    <div className="dash-event-card-status-icon" style={{ width: 50 }}>
                        {getDeviceStatusIcon(data)}
                    </div>
                    {getStatus(data.monitorStatus, t)}
                    <div style={{ width: 140 }} className="dash-event-card-text dash-event-card-text-1 dash-event-card-text-highlight mr-3">
                        {getDisplayDate(data.statusStartTime)}
                    </div>
                    <div style={{ width: 100 }} className="dash-event-card-text dash-event-card-text-1 mr-3">
                        {getDisplayTime(data.statusStartTime)}
                    </div>
                    <div style={{ width: 100 }} className="dash-event-card-text dash-event-card-text-1 mr-3">
                        {getDuration(data.statusStartTime, data.statusEndTime)}
                    </div>
                    <div style={{ width: 50 }} className="dash-event-card-text mr-3">
                        {getDisplayFloor(data.floorName, t)}
                    </div>
                    <div style={{ width: 100 }} className="dash-event-card-text mr-3">
                        {getDisplayRoom(data, t)}
                    </div>
                    <div style={{ width: 100 }} className="dash-event-card-text mr-3">
                        {getDisplayLocation(data, t)}
                    </div>
                    <div style={{ width: 180 }} className="dash-event-card-text mr-3 dash-history-edit-btn">
                        {data['reportReasonID'] !== undefined && data['reportReasonID'] !== null ? (
                            <Fragment>
                                <span className="me-1">{getEventReportReasonValue(data['reportReasonID'], staticEventReportReason, t)}</span>
                                {isShowEventEditBtn(data.monitorStatus) ? <EditIcon scale={1.1} fill={colors.handledResolved} /> : null}
                            </Fragment>
                        ) : isShowEventEditBtn(data.monitorStatus) ? (
                            <Fragment>
                                <span className="me-1">{t('Common.FillInEventChangeReason')}</span>
                                <EditIcon scale={1.1} fill={colors.handledResolved} />
                            </Fragment>
                        ) : (
                            ''
                        )}
                    </div>
                    {/*<div style={{width: 40}} className="dash-event-card-text mr-3">{data.carerName !== null ? data.carerName: "-"}</div>*/}
                    <div style={{ width: 250, marginRight: 20, paddingTop: 5 }} className="dash-event-card-text d-inline-block text-truncate">
                        {formatManualReplyData(t('Common.MessageSource.bnet-dashboard'), dashboardReply, t)}
                    </div>
                    <div style={{ width: 250, marginRight: 20, paddingTop: 5 }} className="dash-event-card-text d-inline-block text-truncate">
                        {formatManualReplyData(t('Common.MessageSource.bnet-opuschat'), opuschatReply, t)}
                    </div>
                    <div style={{ width: 100, marginRight: 20, paddingTop: 5 }} className="dash-event-card-text d-inline-block text-truncate">
                        {mqttReply !== undefined && mqttReply !== '0' ? t('Common.PhysicalBtnPressBtn') : ''}
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default HistoryCard;
