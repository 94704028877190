
import moment from "moment";
import { getDeviceStatus, getDevices, getDevicesCurrentStatus } from "../../services/device";
import { apiError, getDeviceCurrentStatusSuccess, getDeviceSuccess } from "./reducer";
import { getDeviceStatusListSuccess } from "../status/reducer";

export const getAllDevices = () => async (dispatch) => {
  try {
    var data = await getDevices();
    let timer = {};
    let result = { ...data.data, timer: timer };
    if (data) {
      let now = moment().valueOf();
      for (let key in data.data.devices) {
        let element = data.data.devices[key];
        if (element["statusStarttime"] === null || element["statusStarttime"] === 0) {
          timer[element["id"]] = null;
        }
        else {
          timer[element["id"]] = now - element["statusStarttime"];
        }
      };
    }
    dispatch(getDeviceSuccess(result));

    var currentStatusData = await getDevicesCurrentStatus();
    dispatch(getDeviceCurrentStatusSuccess(currentStatusData.data));
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};