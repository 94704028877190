import { ALL_EVNET_HANDLING, ALL_FLOORS, ALL_LOCATIONS, ALL_MONITOR_STATUS, ALL_MONITOR_TYPES, ALL_MONITOR_TYPE_STATUS, getAllEventHandlingOption, getAllFloorOption, getAllStatusOption, getAllTypeOption } from "../Components/Common/DeviceFilter";
import { getEventHanldingName, getFloorName, getLocationName, getMonitorTypeName, getStatusName, getStatusTypeName } from "../helpers/data_conversion_helper";

export const convertLocationToDB = (location) => {
    if (location.length === 0) {
        return null;
    }
    let result = {"floor": [], "location": []};
    location.forEach((item) => {
        if (item.value === ALL_FLOORS) {
            return result;
        }
        else if (item.value.includes(ALL_LOCATIONS)) {
            result["floor"].push(item.floor);
        }
        else {
            result["location"].push(parseInt(item.value));
        }
    })
    return result;
}

export const convertLocationToUI = (data, t, floorplan) => {
    let result = [];
    if (data.floor.length === 0 && data.location.length === 0) {
        result.push(getAllFloorOption(t));
    }
    else {
        data.floor.forEach((d) => {
            for (let f = 0; f < floorplan.length; f++) {
                let floor = floorplan[f];
                if (floor.id === d) {
                    result.push(getFloorName(floor, t));
                }
            }
        })

        data.location.forEach((d) => {
            for (let f = 0; f < floorplan.length; f++) {
                let floor = floorplan[f];
                for (let l = 0; l < floor.locations.length; l++) {
                    let location = floor.locations[l];
                    if (location.id === d) {
                        result.push(getLocationName(floor, location, t));
                    }
                }
            }
        })
    }
    
    return result;
}

export const convertMonitorTypeToDB = (type) => {
    if (type.length === 0) {
        return null;
    }
    let result = [];
    type.forEach(item => {
        if (item.value === ALL_MONITOR_TYPES) {
            return result;
        }
        else {
            result.push(parseInt(item.value));
        }
    })
    return result;
}

export const convertMonitorTypeToUI = (data, translate, monitorType) => {
    let result = [];
    if (data.length === 0) {
        result.push(getAllTypeOption(translate));
    }
    else {
        data.forEach((d) => {
            for (let t = 0; t < monitorType.length; t++) {
                let type = monitorType[t];
                if (type.id === d) {
                    result.push(getMonitorTypeName(type, translate));
                }
            }
        })
    }
    
    return result;
}

export const convertMonitorStatusToDB = (status) => {
    if (status.length === 0) {
        return null;
    }
    let result = {"statusType": [], "status": []};
    status.forEach(item => {
        console.log(item);
        if (item.value === ALL_MONITOR_STATUS) {
            return result;
        }
        else if (item.value.includes(ALL_MONITOR_TYPE_STATUS)) {
            result["statusType"].push(parseInt(item.status));
        }
        else {
            result["status"].push(item.value);
        }
    })
    return result;
}

export const convertMonitorStatusToUI = (data, t, statusData) => {
    let result = [];
    if (data.statusType.length === 0 && data.status.length === 0) {
        result.push(getAllStatusOption(t));
    }
    else {
        data.statusType.forEach((d) => {
            for (const st in statusData) {
                if (parseInt(st) === d) {
                    console.log("push " + d);
                    result.push(getStatusTypeName(st, t));
                }
            }
        })

        data.status.forEach((d) => {
            for (const st in statusData) {
                let value = statusData[st];
                console.log("value1: " + value);
                value.forEach((status) => {
                    console.log("status1: " + status);
                    if (status === d) {
                        console.log("push1 " + d);
                        result.push(getStatusName(st, status, t));
                    }
                })
            }
        })
    }
    
    return result;
}

export const convertEventHandlingToDB = (eventHandling) => {
    if (eventHandling.length === 0) {
        return null;
    }
    let result = [];
    eventHandling.forEach(item => {
        if (item.value === ALL_EVNET_HANDLING) {
            return result;
        }
        else {
            result.push(parseInt(item.value));
        }
    })
    return result;
}

export const convertEventHandlingToUI = (data, translate, eventHandling) => {
    let result = [];
    if (data.length === 0) {
        result.push(getAllEventHandlingOption(translate));
    }
    else {
        data.forEach((d) => {
            result.push(getEventHanldingName({id: d, value: eventHandling["data"][d]}, translate));
        })
    }
    
    return result;
}

export const convertArrayToString = (array) => {
    if (array.length === 0) {
        return null;
    }
    let result = "";
    array.forEach((item) => {
        if (result !== "") {
            result = result + ", "
        }
        result = result + item.label;
    })
    return result;
}