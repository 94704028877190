import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { SummaryDate, SummaryFilter } from "../../Components/Common/SVG";
import DeviceFilter, { getAllFloorOption } from "../../Components/Common/DeviceFilter";
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import { getStaticFloorPlan } from "../../slices/staticData/thunk";
import { MandarinTraditional } from "flatpickr/dist/l10n/zh-tw.js";
import { english } from "flatpickr/dist/l10n/default.js";
import ToiletFallSummaryChart from "./ToiletFallSummaryChart";
import ToiletOTSummaryChart from "./ToiletOTSummaryChart";
import { getSummaryToiletFall, getSummaryBedFall, getSummaryToiletArrival, getSummaryToiletOT, getSummaryBedArrival, getSummaryToiletUsingAverageTime, getSummaryToiletUsingFrequence } from "../../slices/summary/thunk";
import BedFallSummaryChart from "./BedFallSummaryChart";
import { setReportEventSelectedMultiFloorPlan } from "../../slices/summary/reducer";
import ToiletArriveTimeSummaryChart from "./ToiletArriveTimeSummaryChart";
import BedArriveTimeSummaryChart from "./BedArriveTimeSummaryChart";
import ToiletUsingAverageTimeSummaryChart from "./ToiletUsingAverageTimeSummaryChart";
import ToiletUsingFrequenceSummaryChart from "./ToiletUsingFrequenceSummaryChart";

function Summary() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  document.title = t('PageSummary.DocumentTitle');
  let defaultDate = new Date();
  defaultDate.setMonth(defaultDate.getMonth() - 1);
  const [dateRange, setDateRange] = useState([new Date(), defaultDate]);
  const floorplan = useSelector((state => state.StaticData.floorplan));
  const selectedMultiFloorPlan = useSelector(state => state.Summary.selectedMultiFloorPlan);

  useEffect(() => {
    dispatch(getStaticFloorPlan());
    setSelectedMultiFloorPlan([getAllFloorOption(t)]);
  }, []);

  let calLang = english;
  if (i18n.language === "cn") {
    calLang = MandarinTraditional;
  }

  useEffect(() => {
    if (selectedMultiFloorPlan !== undefined && selectedMultiFloorPlan !== null && selectedMultiFloorPlan.length > 0 && dateRange.length === 2) {
      dispatch(getSummaryToiletFall(selectedMultiFloorPlan, dateRange));
      dispatch(getSummaryToiletOT(selectedMultiFloorPlan, dateRange));
      dispatch(getSummaryToiletArrival(selectedMultiFloorPlan, dateRange));
      dispatch(getSummaryBedFall(selectedMultiFloorPlan, dateRange));
      dispatch(getSummaryBedArrival(selectedMultiFloorPlan, dateRange));
      dispatch(getSummaryToiletUsingAverageTime(selectedMultiFloorPlan, dateRange));
      dispatch(getSummaryToiletUsingFrequence(selectedMultiFloorPlan, dateRange));
    }
  }, [dateRange, selectedMultiFloorPlan])

  const setSelectedMultiFloorPlan = (data) => {
    if (data !== undefined && data !== null) {
      dispatch(setReportEventSelectedMultiFloorPlan(data));
    }
  }

  let setToiletUsingFrequenceSummaryPeriod = (form) => {
    dispatch(getSummaryToiletUsingFrequence(selectedMultiFloorPlan, dateRange, form));
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <div className="d-flex flex-row flex-wrap">
              <div className="d-flex flex-row summary-filter-item-container">
                <div className="summary-filter-icon-box d-flex align-items-center justify-content-center">
                  <SummaryFilter scale={1.2} />
                </div>
                <div className="summary-filter-floor-box">
                  <DeviceFilter
                    isShowMonitorType={false}
                    isShowStatusType={false}
                    isShowEventHandling={false}
                    floorplan={floorplan} monitorType={[]} monitorStatus={[]}
                    selectedMultiFloorPlan={selectedMultiFloorPlan}
                    selectedMultiMonitorType={[]}
                    selectedMultiMonitorStatus={[]}
                    setSelectedMultiFloorPlan={setSelectedMultiFloorPlan}
                    setSelectedMultiMonitorType={() => { }}
                    setSelectedMultiMonitorStatus={() => { }} />
                </div>
              </div>
              <div className="d-flex flex-row summary-filter-item-container">
                <div className="summary-filter-icon-box d-flex align-items-center justify-content-center">
                  <SummaryDate scale={1.2} />
                </div>
                <div className="summary-filter-date-box">
                  <Flatpickr
                    className="form-control"
                    onChange={(e) => { setDateRange(e) }
                    }
                    value={dateRange || ""}
                    options={{
                      mode: "range",
                      dateFormat: "Y-m-d",
                      locale: {
                        ...calLang
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="summary-chart-container">
              <Row>
                <Col xs={12} md={6} xl={4}>
                  <ToiletFallSummaryChart />
                </Col>
                <Col xs={12} md={6} xl={4}>
                  <ToiletOTSummaryChart />
                </Col>
                <Col xs={12} md={6} xl={4}>
                  <ToiletArriveTimeSummaryChart />
                </Col>
                <Col xs={12} md={6} xl={4}>
                  <BedFallSummaryChart />
                </Col>
                <Col xs={12} md={6} xl={4}>
                  <BedArriveTimeSummaryChart />
                </Col>
                <Col xs={12} md={6} xl={4}>
                  <ToiletUsingAverageTimeSummaryChart />
                </Col>
                <Col xs={12}>
                  <ToiletUsingFrequenceSummaryChart setToiletSummaryPeriod={setToiletUsingFrequenceSummaryPeriod} />
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Summary;
