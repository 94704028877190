import React, { useEffect, useRef, useState } from 'react';

//import Scss
import './assets/scss/themes.scss';

//imoprt Route
import Route from './Routes';
import { setDeviceAlarmOff, setDeviceConnection, setDeviceStatus, setDeviceStatusNoOfPpl, setMQConnection, setPin, setSocketConnection, timerIncrement } from './slices/device/reducer';
import DataConnector from './helpers/DataConnector';
import { useDispatch, useSelector } from 'react-redux';
import Alarm_audio from './assets/sounds/alarm.mp3';
import AlarmConfirmModal from './Components/Common/AlarmConfirmModal';
import { getAllDevices } from './slices/thunks';
import { addStatusChangeReply, updateDeviceStatus, updateStatusChangeReportSuccess } from './slices/status/reducer';
import { updateStatusChangeReportInReportSuccess } from './slices/event/reducer';
import { getAllDeviceStatus } from './slices/status/thunk';

const onConnected = dispatch => {
    dispatch(setSocketConnection(true));
    dispatch(getAllDevices());
    dispatch(getAllDeviceStatus());
};
const onDisconnected = dispatch => {
    dispatch(setSocketConnection(false));
};
const onMQConnected = dispatch => {
    dispatch(setMQConnection(true));
};
const onMQDisconnected = dispatch => {
    dispatch(setMQConnection(false));
};
const onDeviceConnected = (dispatch, id, changeStatusTime) => {
    dispatch(setDeviceConnection({ id: id, isConnected: true, changeStatusTime: changeStatusTime }));
};
const onDeviceDisconnected = (dispatch, id, changeStatusTime) => {
    dispatch(setDeviceConnection({ id: id, isConnected: false, changeStatusTime: changeStatusTime }));
};
const onDeviceStatusChange = (dispatch, id, realtimeStatus, realtimeStatusStartTime, displayStatus, displayStatusStartingTime, updatedStatus, displayStatusChangeID, realtimeStatusChangeID) => {
    if (updatedStatus !== undefined) {
        dispatch(updateDeviceStatus(updatedStatus));
    }
    dispatch(
        setDeviceStatus({
            id: id,
            realtimeStatus: realtimeStatus,
            realtimeStatusStartTime: realtimeStatusStartTime,
            displayStatus: displayStatus,
            displayStatusStartingTime: displayStatusStartingTime,
            displayStatusChangeID: displayStatusChangeID,
            realtimeStatusChangeID: realtimeStatusChangeID
        })
    );
};
const onDeviceStatusReportChange = (dispatch, statusChangeID, reasonID) => {
    dispatch(updateStatusChangeReportSuccess({ statusChangeID: statusChangeID, reason: reasonID }));
    dispatch(updateStatusChangeReportInReportSuccess({ statusChangeID: statusChangeID, reason: reasonID }));
};
const onPinUpdate = (dispatch, id, isPin) => {
    dispatch(setPin({ id: id, isPin: isPin }));
};
const onForceReFresh = () => {
    window.location.reload();
};
const onStatusPplUpdate = (dispatch, id, noOfPpl) => {
    dispatch(setDeviceStatusNoOfPpl({ id: id, noOfPpl: noOfPpl }));
};
const onFrozenWithoutChangeStatus = (dispatch, id) => {
    dispatch(setDeviceAlarmOff({ id: id }));
};
const onUpdateHistoryReply = (dispatch, data) => {
    dispatch(addStatusChangeReply({ ...data }));
};

function App() {
    console.log('Environment: ' + process.env.NODE_ENV);
    console.log('Stage Environment: ' + process.env.REACT_APP_STAGE);
    const intervalRef = useRef();
    const dispatch = useDispatch();
    const isAlarmPlaying = useSelector(state => state.Device.isAlarmPlaying);
    const [audio, setAudio] = useState(new Audio(Alarm_audio));
    const isUserLogout = useSelector(state => state.Profile?.user?.id === undefined);
    const [isShowAlarmConfirmModal, setShowAlarmConfirmModal] = useState(false);

    const setup = () => {
        // start timer
        intervalRef.current = setInterval(() => {
            dispatch(timerIncrement());
        }, 1000);

        dispatch(getAllDevices());

        // start socket
        const dataConnector = DataConnector.getInstance();
        dataConnector.createSocket();
        dataConnector.setCBs(
            () => {
                onConnected(dispatch);
            },
            () => {
                onDisconnected(dispatch);
            },
            () => {
                onMQConnected(dispatch);
            },
            () => {
                onMQDisconnected(dispatch);
            },
            data => {
                onDeviceConnected(dispatch, data.id, data.changeStatusTime);
            },
            data => {
                onDeviceDisconnected(dispatch, data.id, data.changeStatusTime);
            },
            data => {
                onDeviceStatusChange(dispatch, data.id, data.realtimeStatus, data.realtimeStatusStartTime, data.displayStatus, data.displayStatusStartTime, data.updatedStatus, data.displayStatusChangeID, data.realtimeStatusChangeID);
            },
            data => {
                onDeviceStatusReportChange(dispatch, data.statusChangeID, data.reasonID);
            },
            data => {
                onPinUpdate(dispatch, data.id, data.isPin);
            },
            onForceReFresh,
            data => {
                onStatusPplUpdate(dispatch, data.id, data.noOfPpl);
            },
            data => {
                onFrozenWithoutChangeStatus(dispatch, data.deviceID);
            },
            data => {
                onUpdateHistoryReply(dispatch, data);
            }
        );
        dataConnector.setup();

        window.addEventListener('beforeunload', clearIntervalAndSaveData);
        return () => {
            clearIntervalAndSaveData();
            dataConnector.closeSocket();
            if (audio !== undefined) {
                audio.pause();
                audio.currentTime = 0;
            }
        };
    };
    useEffect(() => {
        if (!isUserLogout && (intervalRef.current === null || intervalRef.current !== undefined)) {
            return setup();
        }
    }, []);

    const clearIntervalAndSaveData = () => {
        // Clear the interval and save any necessary data
        clearInterval(intervalRef.current);
        intervalRef.current = null;
    };

    useEffect(() => {
        if (isUserLogout) {
            clearIntervalAndSaveData();
            const dataConnector = DataConnector.getInstance();
            dataConnector.closeSocket();
            if (audio !== undefined) {
                audio.pause();
                audio.currentTime = 0;
            }
        } else {
            setShowAlarmConfirmModal(true);
            if (intervalRef.current === null || intervalRef.current === undefined) {
                return setup();
            }
        }
    }, [isUserLogout]);

    useEffect(() => {
        if (audio !== undefined) {
            if (isAlarmPlaying) {
                console.log('play sound');
                audio.loop = true;
                audio.play();
            } else {
                console.log('pause sound');
                audio.pause();
                audio.currentTime = 0;
            }
        }
    }, [isAlarmPlaying]);

    return (
        <React.Fragment>
            <AlarmConfirmModal
                show={isShowAlarmConfirmModal}
                closeModal={() => {
                    setShowAlarmConfirmModal(false);
                }}
            />
            <Route />
        </React.Fragment>
    );
}

export default App;
