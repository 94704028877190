import { createSlice } from '@reduxjs/toolkit';
import { ALL_FLOORS, ALL_LOCATIONS, ALL_MONITOR_STATUS, ALL_MONITOR_TYPE_STATUS, ALL_MONITOR_TYPES } from '../../Components/Common/DeviceFilter';
import { MONITOR_STATUS, MONITOR_TYPE } from '../../common/constants';

export const initialState = {
    totalDevices: {
        BED: 0,
        TOILET: 0
    },
    devicesID: [],
    isAlarmPlaying: false, // check all devices status
    devices: {},
    timer: {},
    error: {},
    isMQConnected: false,
    isSocketConnected: false,
    lastUpdatedDeviceStatus: new Date().getTime(),
    layout: {
        isListView: false
    }
};

const deviceSlice = createSlice({
    name: 'Device',
    initialState,
    reducers: {
        apiError(state, action) {
            state.error = action.payload;
        },
        getDeviceSuccess(state, action) {
            console.log('getDeviceSuccess');
            console.log(action);
            let isAlarmPlaying = false;
            Object.values(action.payload.devices).forEach(item => {
                if ((item.currentMonitorStatus === MONITOR_STATUS.TOILET.FALL || item.currentMonitorStatus === MONITOR_STATUS.TOILET.NOMOVE || item.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT || item.currentMonitorStatus === MONITOR_STATUS.BED.FALL) && item.isConnected) {
                    isAlarmPlaying = true;
                }
            });
            return {
                ...state,
                isAlarmPlaying: isAlarmPlaying,
                ...action.payload,
                devices: action.payload.devices,
                timer: action.payload.timer
            };
        },
        getDeviceCurrentStatusSuccess(state, action) {
            console.log('getDeviceCurrentStatusSuccess');
            console.log(action);
            let updatedDevice = {};
            for (let key in action.payload) {
                if (action.payload.hasOwnProperty(key)) {
                    const obj = action.payload[key];
                    let device = { ...state.devices[key] };
                    if (device !== undefined) {
                        device['noOfPpl'] = obj.noOfPpl;
                        updatedDevice[key] = device;
                    }
                }
            }
            return {
                ...state,
                devices: { ...state.devices, ...updatedDevice }
            };
        },
        timerIncrement: (state, action) => {
            let newTimer = {};
            for (let t in state.timer) {
                if (state.timer[t] !== null) {
                    newTimer[t] = state.timer[t] + 1000;
                }
            }
            return {
                ...state,
                timer: newTimer
            };
        },
        filterDevices: (state, action) => {
            console.log(action);
            let floor = [];
            let location = [];
            let isCheckLocation = true;
            let type = [];
            let statusMonitorType = [];
            let status = [];
            let isCheckStatus = true;
            if (action.payload.floor.length > 0) {
                action.payload.floor.forEach(f => {
                    if (f.value === ALL_FLOORS) {
                        floor = null;
                        isCheckLocation = false;
                    } else if (f.value.includes(ALL_LOCATIONS)) {
                        floor.push(f.floor);
                        isCheckLocation = false;
                    } else {
                        location.push(f.value);
                    }
                });

                if (floor !== null && floor.length === 0) {
                    floor = null;
                }
            }

            if (!isCheckLocation && location.length === 0) {
                location = null;
            }

            action.payload.monitorType.forEach(t => {
                if (t.value === ALL_MONITOR_TYPES) {
                    type = null;
                } else {
                    type.push(t.value);
                }
            });

            if (action.payload.status.length > 0) {
                action.payload.status.forEach(s => {
                    if (s.value === ALL_MONITOR_STATUS) {
                        statusMonitorType = null;
                        isCheckStatus = false;
                    } else if (s.value.includes(ALL_MONITOR_TYPE_STATUS)) {
                        statusMonitorType.push(s.status);
                        isCheckStatus = false;
                    } else {
                        status.push(s.value);
                        if (s.value.includes(MONITOR_STATUS.TOILET.USING)) {
                            status.push(MONITOR_STATUS.TOILET.USING_OT_PAUSED);
                        }
                    }
                });

                if (statusMonitorType !== null && statusMonitorType.length === 0) {
                    statusMonitorType = null;
                }
            }

            if (!isCheckStatus && status.length === 0) {
                status = null;
            }

            state.devicesID.forEach(id => {
                let item = state.devices[id];
                item.isShow = true;

                let shouldShow = false;
                if (floor !== null) {
                    if (floor.length > 0) {
                        if (floor.includes(item.floorID.toString())) {
                            shouldShow = true;
                        }
                    }
                } else if (location === null) {
                    shouldShow = true;
                }

                if (!shouldShow) {
                    if (location !== null) {
                        if (location.length > 0) {
                            if (location.includes(item.locationID.toString())) {
                                shouldShow = true;
                            }
                        }
                    }
                }
                item.isShow = shouldShow;

                if (item.isShow) {
                    if (type !== null) {
                        if (type.length > 0) {
                            if (!type.includes(item.monitorTypeID.toString())) {
                                item.isShow = false;
                            }
                        } else if (type.length === 0) {
                            item.isShow = false;
                        }
                    }

                    if (item.isShow) {
                        let shouldShow = false;
                        if (statusMonitorType !== null) {
                            if (statusMonitorType.length > 0) {
                                if (statusMonitorType.includes(item.monitorTypeID.toString())) {
                                    shouldShow = true;
                                }
                            }
                        } else if (status === null) {
                            shouldShow = true;
                        }

                        if (!shouldShow) {
                            if (status !== null) {
                                if (status.length > 0) {
                                    if (status.includes(item.currentMonitorStatus)) {
                                        shouldShow = true;
                                    }
                                }
                            }
                        }

                        item.isShow = shouldShow;
                    }
                }
            });
        },
        setMQConnection: (state, action) => {
            state.isMQConnected = action.payload;
        },
        setSocketConnection: (state, action) => {
            state.isSocketConnected = action.payload;
        },
        setDeviceConnection: (state, action) => {
            let device = { ...state.devices[action.payload.id] };
            if (device !== undefined && device.isConnected !== action.payload.isConnected) {
                device['isConnected'] = action.payload.isConnected;
                device['statusStarttime'] = action.payload.changeStatusTime;
                let time = new Date() - new Date(action.payload.changeStatusTime);

                let devices = { ...state.devices, [action.payload.id]: device };
                let isAlarmPlaying = false;
                Object.values(devices).forEach(item => {
                    if (
                        (item.currentMonitorStatus === MONITOR_STATUS.TOILET.FALL || item.currentMonitorStatus === MONITOR_STATUS.TOILET.NOMOVE || item.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT || item.currentMonitorStatus === MONITOR_STATUS.BED.FALL) &&
                        item.isConnected &&
                        (item.isAlarmPlaying === undefined || item.isAlarmPlaying)
                    ) {
                        isAlarmPlaying = true;
                    }
                });

                return {
                    ...state,
                    devices: devices,
                    timer: {
                        ...state.timer,
                        [action.payload.id]: time
                    },
                    isAlarmPlaying: isAlarmPlaying
                };
            }
            return state;
        },
        setDeviceStatus: (state, action) => {
            console.log(action);
            let device = { ...state.devices[action.payload.id] };
            if (device !== undefined) {
                if (action.payload.displayStatusStartingTime > 0) {
                    if (device['currentMonitorStatus'] !== action.payload.displayStatus || device['statusStarttime'] !== action.payload.displayStatusStartingTime || device['statusChangeID'] !== action.payload.displayStatusChangeID) {
                        device['currentMonitorStatus'] = action.payload.displayStatus;
                        device['statusStarttime'] = action.payload.displayStatusStartingTime;
                        device['statusChangeID'] = action.payload.displayStatusChangeID;
                        delete device.isAlarmPlaying;
                    }
                } else {
                    if (device['currentMonitorStatus'] !== action.payload.realtimeStatus || device['statusStarttime'] !== action.payload.realtimeStatusStartTime || device['statusChangeID'] !== action.payload.realtimeStatusChangeID) {
                        device['currentMonitorStatus'] = action.payload.realtimeStatus;
                        device['statusStarttime'] = action.payload.realtimeStatusStartTime;
                        device['statusChangeID'] = action.payload.realtimeStatusChangeID;
                        delete device.isAlarmPlaying;
                    }
                }

                let devices = { ...state.devices, [action.payload.id]: device };
                let isAlarmPlaying = false;
                Object.values(devices).forEach(item => {
                    if (
                        (item.currentMonitorStatus === MONITOR_STATUS.TOILET.FALL || item.currentMonitorStatus === MONITOR_STATUS.TOILET.NOMOVE || item.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT || item.currentMonitorStatus === MONITOR_STATUS.BED.FALL) &&
                        item.isConnected &&
                        (item.isAlarmPlaying === undefined || item.isAlarmPlaying)
                    ) {
                        isAlarmPlaying = true;
                    }
                });

                let time = new Date() - new Date(device['statusStarttime']);
                return {
                    ...state,
                    isAlarmPlaying: isAlarmPlaying,
                    devices: { ...state.devices, [action.payload.id]: device },
                    lastUpdatedDeviceStatus: new Date().getTime(),
                    timer: {
                        ...state.timer,
                        [action.payload.id]: time
                    }
                };
            }
            return state;
        },
        setDeviceAlarmOff: (state, action) => {
            console.log(action);
            let device = { ...state.devices[action.payload.id], isAlarmPlaying: false };
            if (device !== undefined) {
                let devices = { ...state.devices, [action.payload.id]: device };
                let isAlarmPlaying = false;
                Object.values(devices).forEach(item => {
                    if (
                        (item.currentMonitorStatus === MONITOR_STATUS.TOILET.FALL || item.currentMonitorStatus === MONITOR_STATUS.TOILET.NOMOVE || item.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT || item.currentMonitorStatus === MONITOR_STATUS.BED.FALL) &&
                        item.isConnected &&
                        (item.isAlarmPlaying === undefined || item.isAlarmPlaying)
                    ) {
                        isAlarmPlaying = true;
                    }
                });

                return {
                    ...state,
                    isAlarmPlaying: isAlarmPlaying,
                    devices: { ...state.devices, [action.payload.id]: device },
                    lastUpdatedDeviceStatus: new Date().getTime()
                };
            }
            return state;
        },
        setPin: (state, action) => {
            let device = { ...state.devices[action.payload.id] };
            if (device !== undefined) {
                device['pin'] = action.payload.isPin;
                // check where is the device in list
                let deviceList = state.devicesID;
                let newDeviceList = [];
                if (action.payload.isPin === true) {
                    newDeviceList.push(action.payload.id);
                    deviceList.forEach(i => {
                        if (i !== action.payload.id) {
                            newDeviceList.push(i);
                        }
                    });
                } else {
                    let isInserted = false;
                    deviceList.forEach(i => {
                        if (i !== action.payload.id && state.devices[i]['pin']) {
                            newDeviceList.push(i);
                        } else if (i < action.payload.id) {
                            newDeviceList.push(i);
                        } else if (i > action.payload.id) {
                            if (!isInserted) {
                                newDeviceList.push(action.payload.id);
                                isInserted = true;
                            }
                            newDeviceList.push(i);
                        }
                    });

                    if (!isInserted) {
                        newDeviceList.push(action.payload.id);
                    }
                }
                return {
                    ...state,
                    devicesID: newDeviceList,
                    devices: { ...state.devices, [action.payload.id]: device }
                };
            }
            return state;
        },
        setDeviceStatusNoOfPpl: (state, action) => {
            let device = { ...state.devices[action.payload.id] };
            if (device !== undefined) {
                device['noOfPpl'] = action.payload.noOfPpl;
            }
            return {
                ...state,
                devices: { ...state.devices, [action.payload.id]: device }
            };
        },
        setIsListView: (state, action) => {
            return {
                ...state,
                layout: {
                    isListView: action.payload
                }
            };
        },
        resetState: state => initialState
    },
    extraReducers: builder => {}
});

export const { apiError, getDeviceSuccess, getDeviceCurrentStatusSuccess, timerIncrement, filterDevices, setMQConnection, setSocketConnection, setDeviceConnection, setDeviceStatus, setPin, setIsListView, setDeviceStatusNoOfPpl, resetState, setDeviceAlarmOff } = deviceSlice.actions;

export default deviceSlice.reducer;
