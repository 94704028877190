import { GENDER, LOCATION_TYPE, MONITOR_STATUS, MONITOR_TYPE, TIMEZONE } from '../common/constants';
import moment from 'moment-timezone';
import colors from '../assets/scss/config/saas/_colors.scss';

export const MONITOR_MESSAGE_SOURCE = {
    MQTT: 'bnet-mqtt',
    INTERNAL: 'bnet-internal',
    DASHBOARD: 'bnet-dashboard',
    OPUSCHAT: 'bnet-opuschat'
};

export const getDisplayFloor = (value, t) => {
    return value + t('Common.FloorConnector') + t('Common.FloorShortForm');
};

export const getDisplayRoom = (data, t) => {
    let room = '-';
    if (data.locationTypeName === LOCATION_TYPE.ROOM) {
        room = t('Common.RoomNumber') + data.locationName + t('Common.Space') + t('Common.RoomUnit');
    }
    return room;
};

export const getDisplayLocation = (data, t) => {
    if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
        if (data.isForDisable) {
            if (data.forGender) {
                if (data.forGender === GENDER.ALL) {
                    return t('Common.DisableToilet') + t('Common.Space') + t('Common.Toilet') + t('Common.Space') + data.monitorLocationName;
                } else {
                    return t('Common.DisableToilet') + t('Common.Space') + t('Common.Gender.' + data.forGender) + t('Common.Space') + t('Common.DisableToilet2') + t('Common.Space') + data.monitorLocationName;
                }
            }
        } else {
            if (data.forGender === GENDER.ALL) {
                return t('Common.Space') + t('Common.Toilet') + t('Common.Space') + data.monitorLocationName;
            } else {
                return t('Common.Space') + t('Common.Gender.' + data.forGender) + t('Common.Space') + t('Common.Toilet') + t('Common.Space') + data.monitorLocationName;
            }
        }
    } else {
        return t('Common.BedNumber') + data.monitorLocationName + t('Common.Space') + t('Common.BedUnit');
    }
};

export const getDisplayDate = data => {
    if (data === undefined || data === null || data === 0) {
        return '';
    }
    let t = moment.unix(data / 1000);
    const formattedTime = t.format('YYYY-MM-DD');
    return formattedTime;
};

export const getDisplayTime = data => {
    if (data === undefined || data === null || data === 0) {
        return '';
    }
    let t = moment.unix(data / 1000);
    const formattedTime = t.format('HH:mm:ss');
    return formattedTime;
};

export const getDisplayDatetimeFromTimestamp = milliseconds => {
    if (milliseconds === undefined || milliseconds === null) {
        return '-';
    } else if (milliseconds < 0) {
        return '00:00:00';
    }
    let totalSeconds = Math.floor(milliseconds / 1000);
    let hours = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    let seconds = totalSeconds - hours * 3600 - minutes * 60;
    return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
};

export const getDisplayDatetime = data => {
    if (data === undefined || data === null) {
        return '-';
    }
    let t = moment.unix(data / 1000);
    const formattedTime = t.format('YYYY-MM-DD HH:mm:ss');
    return formattedTime;
};

export const getIdleBox = text => {
    return (
        <div style={{ backgroundColor: colors.eventMonitorIdle }} className="dash-device-card-text-grid dash-device-card-text-grid-label">
            {text}
        </div>
    );
};

export const getUsingBox = text => {
    return (
        <div style={{ backgroundColor: colors.eventMonitorUsing }} className="dash-device-card-text-grid dash-device-card-text-grid-label">
            {text}
        </div>
    );
};

export const getDangerBox = text => {
    return (
        <div style={{ backgroundColor: colors.eventAlarmOn, color: colors.off }} className="dash-device-card-text-grid dash-device-card-text-grid-label">
            {text}
        </div>
    );
};

export const getDangerAlarmBox = text => {
    return (
        <div style={{ backgroundColor: colors.eventAlarmOn }} className="dash-device-card-text-grid dash-device-card-text-grid-label dash-danger-status-box">
            {text}
        </div>
    );
};

export const getNoStatusBox = text => {
    return (
        <div style={{ backgroundColor: colors.off }} className="dash-device-card-text-grid dash-device-card-text-grid-label">
            {text}
        </div>
    );
};

export const getNoStatusBoxDescription = text => {
    return (
        <div style={{ backgroundColor: colors.off, color: colors.blue }} className="dash-device-card-text-grid dash-device-card-text-grid-label">
            {text}
        </div>
    );
};

export const isShowEventEditBtn = value => {
    if (value === MONITOR_STATUS.TOILET.FALL || value === MONITOR_STATUS.TOILET.NOMOVE || value === MONITOR_STATUS.TOILET.USING_OT || value === MONITOR_STATUS.BED.FALL) {
        return true;
    }
    return false;
};

export const formatManualReplyData = (topic, data, t) => {
    if (data !== undefined && data.length > 0) {
        return (
            topic +
            ': ' +
            data
                .map(entry => {
                    const { actorName, createdTime, actionName } = entry;

                    // Convert createdTime to desired format (HH:MM:SS)
                    const date = new Date(createdTime);
                    const hours = String(date.getUTCHours()).padStart(2, '0');
                    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
                    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
                    const timeFormatted = `${hours}:${minutes}:${seconds}`;

                    // Format the string
                    const translatedAction = t(`Common.Opuschat.Action.${actionName}`);
                    return `${actorName}[${translatedAction}]`;
                })
                .join(', ')
        ); // Join all formatted strings with a comma and space
    } else {
        return '';
    }
};
