import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { userHandleEvent, userHandledEvent } from '../../services/device';
import { useDispatch, useSelector } from 'react-redux';
import { EventFallToiletGridIcon, EventNomoveToiletGridIcon, EventOTToiletGridIcon } from './SVG';
import { MONITOR_STATUS, MONITOR_TYPE } from '../../common/constants';
import colors from '../../assets/scss/config/saas/_colors.scss';
import { getDangerAlarmBox, getDangerBox, getDisplayFloor, getDisplayLocation, getDisplayRoom } from '../../helpers/display_help';
import { getEventReportReasonOptionValue, getEventReportReasonOptions } from '../../helpers/data_conversion_helper';
import Select from 'react-select';
import chroma from 'chroma-js';
import { setDeviceAlarmOff } from '../../slices/device/reducer';

const getDeviceStatusIcon = data => {
    let item = null;
    let scale = 2;
    if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
        if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_USING_OT || data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT) {
            item = <EventOTToiletGridIcon scale={scale} fill={data.isConnected ? colors.eventDanger : colors.off} fillIcon={data.isConnected ? (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_USING_OT ? colors.off : colors.white) : colors.white} />;
        } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.NOMOVE || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_NOMOVE) {
            item = <EventNomoveToiletGridIcon scale={scale} fill={data.isConnected ? colors.eventDanger : colors.off} fillIcon={data.isConnected ? (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_NOMOVE ? colors.off : colors.white) : colors.white} />;
        } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FALL || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_FALL) {
            item = <EventFallToiletGridIcon scale={scale} fill={data.isConnected ? colors.eventDanger : colors.off} fillIcon={data.isConnected ? (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_FALL ? colors.off : colors.white) : colors.white} />;
        }
    }
    return item;
};

const getLocation = (data, t) => {
    let location = getDisplayLocation(data, t);
    return getDangerBox(location);
};

const getFloor = (data, t) => {
    let displayFloor = getDisplayFloor(data.floorName, t);
    let room = getDisplayRoom(data, t);
    if (room !== '-') {
        room = ' - ' + room;
    } else {
        room = '';
    }
    return getDangerBox(displayFloor + room);
};

const getDescription = (data, t) => {
    if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT || data.currentMonitorStatus === MONITOR_STATUS.TOILET.NOMOVE || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FALL) {
        return getDangerAlarmBox(t('Common.MonitorStatusTypeShortForm.' + data.currentMonitorStatus));
    } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_USING_OT || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_NOMOVE || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_FALL) {
        return getDangerBox(t('Common.MonitorStatusTypeShortForm.' + data.currentMonitorStatus));
    }
};

export const colourStyles = selectColor => {
    return {
        menu: (styles, state) => ({
            ...styles,
            backgroundColor: colors.white
        }),
        menuList: (styles, state) => ({
            ...styles,
            backgroundColor: colors.white
        }),
        control: (styles, state) => ({
            ...styles,
            marginLeft: 6,
            marginRight: 6,
            marginTop: 10,
            backgroundColor: colors.white,
            border: '1px solid #e5e7eb',
            boxShadow: 'none',
            '&:hover': {
                border: '1px solid #e5e7eb'
            },
            minWidth: 200,
            fontSize: '1.07rem'
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(selectColor);
            return {
                ...styles,
                backgroundColor: color.white,
                color: color,
                fontSize: '1.07rem',
                '&:hover': {
                    backgroundColor: color.alpha(0.1).css()
                }
            };
        }
    };
};

const DashboardEventConfirmModal = ({ show, closeModal, isHandlingEvent, deviceID, setOpenErrorModal }) => {
    const { t } = useTranslation();
    const data = useSelector(state => state.Device.devices[deviceID]);
    const staticEventReportReason = useSelector(state => state.StaticData.eventReportReason);
    const [reportReason, setReportReason] = useState(null);
    const [isShowError, setShowError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();

    // Function to reset reportReason to null when show becomes true
    const resetReportReason = () => {
        if (staticEventReportReason['seq']?.length === 1 && (reportReason === null || reportReason === undefined)) {
            let option = getEventReportReasonOptionValue(staticEventReportReason['seq'][0], staticEventReportReason, t);
            setReportReason(option);
        } else {
            setReportReason(null);
        }
        setShowError(false);
    };

    useEffect(() => {
        if (show) {
            resetReportReason();
            setLoading(false);
        }
    }, [show]);

    if (data === undefined) {
        return;
    } else if (
        !(
            data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT ||
            data.currentMonitorStatus === MONITOR_STATUS.TOILET.NOMOVE ||
            data.currentMonitorStatus === MONITOR_STATUS.TOILET.FALL ||
            data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_USING_OT ||
            data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_NOMOVE ||
            data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_FALL
        )
    ) {
        closeModal();
    }

    return (
        <Modal
            isOpen={show}
            toggle={() => {
                closeModal();
            }}
            centered={true}>
            <div className="modal-header d-flex flex-row align-items-center justify-content-center">
                <div className="modal-title" id="varyingcontentModalLabel">
                    {t('DashboardEventConfirmModal.Title')}
                </div>
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                        closeModal();
                    }}></button>
            </div>
            <ModalBody className="d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex flex-row align-items-center justify-content-center w-100">
                    <div style={{ flex: 1 }} className="d-flex flex-row align-items-center justify-content-center">
                        {getDeviceStatusIcon(data)}
                    </div>
                    <div style={{ flex: 2 }}>
                        <div className="ps-1 mb-2 d-flex flex-row align-items-space justify-content-center">
                            <div style={{ flex: 1 }}>{getDescription(data, t)}</div>
                            <div style={{ flex: 1 }}>{getLocation(data, t)}</div>
                        </div>
                        <div className="ps-1">{getFloor(data, t)}</div>
                        {!isHandlingEvent ? (
                            <Select
                                value={reportReason}
                                isMulti={false}
                                isClearable={false}
                                placeholder={t('EditEventInfoModal.ResolvedReasonPlaceholder')}
                                onChange={value => {
                                    setReportReason(value);
                                    if (value !== null) {
                                        setShowError(false);
                                    } else {
                                        setShowError(true);
                                    }
                                }}
                                options={getEventReportReasonOptions(staticEventReportReason, t)}
                                styles={colourStyles(colors.blue)}
                            />
                        ) : null}
                        {isShowError ? <div className="ps-2 dash-event-confirm-modal-btn-error">{t('DashboardEventConfirmModal.ReasonError')}</div> : null}
                        <Button
                            className="btn-md dash-event-confirm-modal-btn mt-2"
                            disabled={isLoading}
                            color="primary"
                            onClick={async () => {
                                if (isHandlingEvent) {
                                    setLoading(true);
                                    let result = await userHandleEvent(data.id, data.statusChangeID);
                                    console.log('userHandleEvent result');
                                    console.log(result);
                                    if (!result.data) {
                                        setOpenErrorModal(true);
                                    }
                                    closeModal();
                                    setLoading(false);
                                } else {
                                    if (reportReason !== null) {
                                        setLoading(true);
                                        let result = await userHandledEvent(data.id, data.statusChangeID, reportReason.value, data.currentMonitorStatus);
                                        if (!result.data) {
                                            setOpenErrorModal(true);
                                        }
                                        setShowError(false);
                                        closeModal();
                                        setLoading(false);
                                    } else {
                                        setShowError(true);
                                    }
                                }
                            }}>
                            {' '}
                            {isHandlingEvent ? t('DashboardEventConfirmModal.BtnConfirmFollowUp') : t('Common.Btn.Confirm')}{' '}
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default DashboardEventConfirmModal;
