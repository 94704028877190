import { getDeviceStatus } from '../../services/device';
import { apiError, getDeviceStatusListSuccess } from './reducer';

export const getAllDeviceStatus = () => async dispatch => {
    try {
        var data = await getDeviceStatus();
        let result = { ...data.data };
        dispatch(getDeviceStatusListSuccess(result));
    } catch (error) {
        console.log(error);
        dispatch(apiError(error));
    }
};
