import React, { useState } from "react";
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DeviceTable from "./DeviceTable";

export const DEVELOPER_TAB_OVERVIEW = "1";

function Developer() {
  const { t } = useTranslation();
  const [developerTab, setDeveloperTab] = useState(DEVELOPER_TAB_OVERVIEW);
  const toggleCustom = (tab) => {
    if (developerTab !== tab) {
      setDeveloperTab(tab);
    }
  };

  return (
    <React.Fragment>
      <div id="page-developer" className="page-content">
        <Container fluid className="p-0">
          <Nav tabs className="nav nav-tabs nav-tabs-custom nav-primary">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: developerTab === DEVELOPER_TAB_OVERVIEW,
                })}
                onClick={() => {
                  toggleCustom(DEVELOPER_TAB_OVERVIEW);
                }}
              >
                {t('PageDeveloper.Tab.Overview')}
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent
            activeTab={developerTab}
            className="text-muted developer-tab-container"
          >
            <TabPane tabId={DEVELOPER_TAB_OVERVIEW}>
              <div className="d-flex flex-column">
                <DeviceTable toggleTag={(tagID) => { toggleCustom(tagID) }} />
              </div>
            </TabPane>
          </TabContent>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default Developer;
