import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Button, Card, CardBody, Modal } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../../assets/scss/config/saas/_colors.scss';
import DashboardSectionHeader from './DashboardSectionHeader';
import { getStaticEventReportReason, getStaticFloorPlan, getStaticMonitorStatus, getStaticMonitorType } from '../../slices/staticData/thunk';
import { getAllDevices } from '../../slices/device/thunk';
import DeviceCard from './DeviceCard';
import { FilterIcon, GirdViewIcon, ListViewIcon } from '../../Components/Common/SVG';
import DeviceFilter, { getAllFloorOption, getAllStatusOption, getAllTypeOption } from '../../Components/Common/DeviceFilter';
import { filterDevices, setIsListView } from '../../slices/device/reducer';
import DashboardEventConfirmModal from '../../Components/Common/DashboardEventConfirmModal';

const DeviceSection = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const totalDevices = useSelector(state => state.Device.totalDevices);
    const currentDevices = useSelector(state => state.Device.devicesID);
    const lastUpdatedDeviceStatus = useSelector(state => state.Device.lastUpdatedDeviceStatus);
    const isListView = useSelector(state => state.Device.layout.isListView);
    const totalBeds = totalDevices.BED;
    const totalToilet = totalDevices.TOILET;
    const deviceSectionRef = useRef(null);
    const [deviceSectionWidth, setDeviceSectionWidth] = useState(0);
    const { isHistoryShown, setHistoryShown } = props;

    const floorplan = useSelector(state => state.StaticData.floorplan);
    const monitorType = useSelector(state => state.StaticData.monitorType);
    const monitorStatus = useSelector(state => state.StaticData.monitorStatus);

    const [selectedMultiFloorPlan, setSelectedMultiFloorPlan] = useState([getAllFloorOption(t)]);
    const [selectedMultiMonitorType, setSelectedMultiMonitorType] = useState([getAllTypeOption(t)]);
    const [selectedMultiMonitorStatus, setSelectedMultiMonitorStatus] = useState([getAllStatusOption(t)]);

    //const [isDeviceEditModelOpen, setDeviceEditModelOpen] = useState(false);
    //const [editDeviceInfo, setEditDeviceInfo] = useState(currentDevices[0]);
    const [confirmEventDeviceID, setConfirmEventDeviceID] = useState(null);
    const [isConfirmingEventHandling, setConfirmingEventHandling] = useState(false);
    const isMQConnected = useSelector(state => state.Device.isMQConnected);
    const isSocketConnected = useSelector(state => state.Device.isSocketConnected);
    const [isOpenErrorModal, setOpenErrorModal] = useState(false);

    const openConfirmAlert = (deviceID, isHandlingEvent) => {
        setConfirmEventDeviceID(deviceID);
        setConfirmingEventHandling(isHandlingEvent);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (isMQConnected && isSocketConnected) {
                try {
                    await Promise.all([dispatch(getStaticFloorPlan()), dispatch(getStaticMonitorType()), dispatch(getStaticMonitorStatus()), dispatch(getStaticEventReportReason())]);

                    dispatch(getAllDevices());
                } catch (error) {
                    console.error('Error fetching static data', error);
                }
            }
        };

        fetchData();
    }, [dispatch, isMQConnected, isSocketConnected]);

    useEffect(() => {
        dispatch(filterDevices({ floor: selectedMultiFloorPlan, status: selectedMultiMonitorStatus, monitorType: selectedMultiMonitorType }));
    }, [selectedMultiFloorPlan, selectedMultiMonitorType, selectedMultiMonitorStatus, lastUpdatedDeviceStatus]);

    useEffect(() => {
        const handleResize = () => {
            if (deviceSectionRef.current) {
                const componentWidth = deviceSectionRef.current.offsetWidth;
                console.log('Component Width:', componentWidth);
                setDeviceSectionWidth(componentWidth);
            }
        };

        // Initial call to handleResize
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let cardWidth = 0;
    if (deviceSectionWidth > 0) {
        let width = deviceSectionWidth - 60;
        let numberOfCard = Math.floor(width / 320);
        if (currentDevices.length <= numberOfCard) {
            width = deviceSectionWidth - 34;
        }
        cardWidth = width / numberOfCard;
        if (cardWidth > 350) {
            cardWidth = 350;
        }
    }

    return (
        <div ref={deviceSectionRef} style={{ position: 'relative', height: '100%' }}>
            <Card className="section-card">
                <CardBody className="section-cardbody">
                    <div className="d-flex flex-column section-container">
                        <div className="d-flex justify-content-between">
                            <DashboardSectionHeader
                                title={t('PageMain.Device.Title')}
                                bed={totalBeds + t('Common.Space1') + (totalBeds > 1 ? t('Common.Beds') : t('Common.Bed'))}
                                toilet={totalToilet + t('Common.Space1') + (totalToilet > 1 ? t('Common.Toilets') : t('Common.Toilet'))}
                                isShown={true}
                            />
                            <div className="p-2 d-flex flex-row">
                                <div
                                    onClick={() => {
                                        dispatch(setIsListView(true));
                                    }}>
                                    <ListViewIcon scale={0.9} on={isListView} />
                                </div>
                                <div
                                    onClick={() => {
                                        dispatch(setIsListView(false));
                                    }}>
                                    <GirdViewIcon scale={0.9} on={!isListView} />
                                </div>
                                <Button
                                    className="btn-sm dash-device-show-history-btn"
                                    color="primary"
                                    onClick={() => {
                                        setHistoryShown();
                                    }}>
                                    {isHistoryShown ? t('PageMain.Device.HistoryBtn.Hide') : t('PageMain.Device.HistoryBtn.Show')}
                                </Button>
                            </div>
                        </div>
                        <div className="d-flex align-items-center flex-wrap dash-filter-container">
                            <div className="dash-filter-icon">
                                <FilterIcon scale={1.2} fill={colors.offText} />
                            </div>
                            <DeviceFilter
                                floorplan={floorplan}
                                monitorType={monitorType}
                                monitorStatus={monitorStatus}
                                selectedMultiFloorPlan={selectedMultiFloorPlan}
                                selectedMultiMonitorType={selectedMultiMonitorType}
                                selectedMultiMonitorStatus={selectedMultiMonitorStatus}
                                setSelectedMultiFloorPlan={setSelectedMultiFloorPlan}
                                setSelectedMultiMonitorType={setSelectedMultiMonitorType}
                                setSelectedMultiMonitorStatus={setSelectedMultiMonitorStatus}
                                isShowEventHandling={false}
                            />
                        </div>
                        {isListView ? (
                            <div className="d-flex flex-column align-items-center card-container">
                                {currentDevices.length > 0 &&
                                    currentDevices.map(data => {
                                        if (data.isShow === undefined || data.isShow) {
                                            return <DeviceCard key={data} data={data} isListView={isListView} openConfirmAlert={openConfirmAlert} />;
                                        }
                                    })}
                            </div>
                        ) : (
                            <div className="d-flex flex-row flex-wrap card-container">
                                {currentDevices.length > 0 &&
                                    currentDevices.map(data => {
                                        if (data.isShow === undefined || data.isShow) {
                                            return <DeviceCard key={data} data={data} isListView={isListView} cardWidth={cardWidth} openConfirmAlert={openConfirmAlert} />;
                                        }
                                    })}
                            </div>
                        )}
                    </div>
                </CardBody>
                <Modal
                    isOpen={isOpenErrorModal}
                    toggle={() => {
                        setOpenErrorModal(false);
                    }}
                    centered>
                    <div className="modal-body text-center p-5">
                        <div className="mt-4">
                            <h4 className="mb-3 fs-4">{t('EditEventInfoModal.Error')}</h4>
                            <p className="text-muted mb-4 fs-5">{t('EditEventInfoModal.ErrorDescription')}</p>
                            <div className="hstack gap-2 justify-content-center">
                                <Button className="btn-success dash-btn-error-modal" onClick={() => setOpenErrorModal(false)}>
                                    {t('Common.Btn.Close')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <DashboardEventConfirmModal
                    show={confirmEventDeviceID !== null}
                    closeModal={() => {
                        setConfirmEventDeviceID(null);
                    }}
                    isHandlingEvent={isConfirmingEventHandling}
                    deviceID={confirmEventDeviceID}
                    setOpenErrorModal={setOpenErrorModal}
                />
                {/*<DeviceEditModal info={editDeviceInfo} isDeviceEditModelOpen={isDeviceEditModelOpen} setDeviceEditModelOpen={(value) => {
                setDeviceEditModelOpen(value);
            }} />*/}
            </Card>
        </div>
    );
};

export default DeviceSection;
