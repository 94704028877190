import React, { useEffect, useState } from "react";
import { Button, Row, Table } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { english } from "flatpickr/dist/l10n/default.js";
import { MandarinTraditional } from "flatpickr/dist/l10n/zh-tw.js";
import { SummaryDate, SummaryFilter } from "../../Components/Common/SVG";
import DeviceFilter, { getAllEventHandlingOption, getAllFloorOption, getAllStatusOption, getAllTypeOption } from "../../Components/Common/DeviceFilter";
import { useDispatch, useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { getEventReport, getEventReportByReportID } from "../../slices/event/thunk";
import { REPORT_PAGE_SIZE } from "../../common/constants";
import ReportRow from "./ReportRow";
import ReportSaveModal from "./ReportSaveModal";
import { REPORT_TAB_OVERVIEW, REPORT_TAB_SAVE_REPORT } from ".";
import { resetEventTableParameters, resetSavedEventListTableParameters, setEventStatusChangeIDEdit, setIsReportLoading, setReportEventDateRange, setReportEventSelectedEventHandling, setReportEventSelectedMultiFloorPlan, setReportEventSelectedMultiMonitorStatus, setReportEventSelectedMultiMonitorType, setReportPage } from "../../slices/event/reducer";
import { exportEventReportService } from "../../services/event";
import EventEditModal from "../../Components/Common/EventEditModal";

function ReportTable(props) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    document.title = t('PageMain.DocumentTitle');
    const dateRange = useSelector(state => state.Event.report.dateRange);
    const floorplan = useSelector((state => state.StaticData.floorplan));
    const monitorType = useSelector((state => state.StaticData.monitorType));    
    const monitorStatus = useSelector((state => state.StaticData.reportMonitorStatus));
    const eventReportReason = useSelector(state => state.StaticData.eventReportReason);
    const selectedMultiFloorPlan = useSelector(state => state.Event.report.selectedMultiFloorPlan);
    const selectedMultiMonitorType = useSelector(state => state.Event.report.selectedMultiMonitorType);
    const selectedMultiMonitorStatus = useSelector(state => state.Event.report.selectedMultiMonitorStatus);
    const selectedMultiEventHandling = useSelector(state => state.Event.report.selectedMultiEventHandling);
    const selectedReportID = useSelector(state => state.Event.report.reportID);
    const lastUpdated = useSelector(state => state.Event.report.lastUpdated);
    const data = useSelector(state => state.Event.report.eventIDs);
    const page = useSelector(state => state.Event.report.pageNumber);
    const [isOpenSaveModal, setOpenSaveModal] = useState(false);
    const isLoading = useSelector(state => state.Event.report.isLoading);
    const totalEvents = useSelector(state => state.Event.report.totalEvents);
    const [isOpenEditEventModal, setOpenEditEventModal] = useState(false);

    useEffect(() => {
        let defaultDate = new Date();
        defaultDate.setMonth(defaultDate.getMonth() - 1);
        if (dateRange === null) {
            setDateRange([new Date().getTime(), defaultDate.getTime()]);
        }
        setSelectedMultiFloorPlan([getAllFloorOption(t)]);
        setSelectedMultiMonitorType([getAllTypeOption(t)]);
        setSelectedMultiMonitorStatus([getAllStatusOption(t)]);
        setSelectedMultiEventHandling([getAllEventHandlingOption(t)]);
    }, [lastUpdated]);

    let calLang = english;
    if (i18n.language === "cn") {
        calLang = MandarinTraditional;
    }

    useEffect(() => {
        if (!isLoading && selectedMultiFloorPlan !== undefined && selectedMultiFloorPlan !== null && selectedMultiFloorPlan.length > 0 && dateRange !== null && dateRange.length === 2 && selectedMultiMonitorType !== undefined && selectedMultiMonitorType !== null && selectedMultiMonitorType.length > 0 && selectedMultiMonitorStatus !== undefined && selectedMultiMonitorStatus !== null && selectedMultiMonitorStatus.length > 0 && selectedMultiEventHandling !== undefined && selectedMultiEventHandling !== null && selectedMultiEventHandling.length > 0) {
            dispatch(setIsReportLoading(true));
            dispatch(getEventReport(selectedMultiFloorPlan, selectedMultiMonitorType, selectedMultiMonitorStatus, selectedMultiEventHandling, dateRange, page, REPORT_PAGE_SIZE));
        }
    }, [page, dateRange, selectedMultiFloorPlan, selectedMultiMonitorType, selectedMultiMonitorStatus, selectedMultiEventHandling])

    useEffect(() => {
        if (selectedReportID !== null) {
            dispatch(setIsReportLoading(true));
            dispatch(getEventReportByReportID(selectedReportID["id"], t, floorplan, monitorType, monitorStatus, eventReportReason)).then(()=>{
                dispatch(setIsReportLoading(false));
                props.toggleTag(REPORT_TAB_OVERVIEW);
            });
        }
    }, [selectedReportID])

    const setDateRange = (data) => {
        if (data !== undefined && data !== null && data.length === 2) {
            dispatch(setReportEventDateRange([new Date(data[0]).getTime(), new Date(data[1]).getTime()]));
        }
    }

    const setSelectedMultiFloorPlan = (data) => {
        if (data !== undefined && data !== null) {
            dispatch(setReportEventSelectedMultiFloorPlan(data));
        }
    }

    const setSelectedMultiMonitorType = (data) => {
        if (data !== undefined && data !== null) {
            dispatch(setReportEventSelectedMultiMonitorType(data));
        }
    }

    const setSelectedMultiMonitorStatus = (data) => {
        if (data !== undefined && data !== null) {
            dispatch(setReportEventSelectedMultiMonitorStatus(data));
        }
    }

    const setSelectedMultiEventHandling = (data) => {
        if (data !== undefined && data !== null) {
            dispatch(setReportEventSelectedEventHandling(data));
        }
    }

    return (
        <Row className="full-width">
            <div className="report-table-spin" style={{display: (isLoading?"inherit":"none")}}>
                <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
            <div style={{display: (isLoading?"none":"flex")}}>
            <div className="d-flex flex-row report-header">
                <div className="d-flex flex-row report-header-filter flex-wrap">
                    <div className="d-flex flex-row report-filter-item-container">
                        <div className="filter-icon-box d-flex align-items-center justify-content-center">
                            <SummaryFilter scale={1.2} />
                        </div>
                        <div className="filter-floor-box">
                            <DeviceFilter 
                            floorplan={floorplan} monitorType={monitorType} monitorStatus={monitorStatus}
                            selectedMultiFloorPlan={selectedMultiFloorPlan} 
                            selectedMultiMonitorType={selectedMultiMonitorType} 
                            selectedMultiMonitorStatus={selectedMultiMonitorStatus} 
                            setSelectedMultiFloorPlan={setSelectedMultiFloorPlan}
                            setSelectedMultiMonitorType={setSelectedMultiMonitorType}
                            setSelectedMultiMonitorStatus={setSelectedMultiMonitorStatus} 
                            setSelectedMultiEventHandling={setSelectedMultiEventHandling}
                            eventHandling={eventReportReason}
                            selectedMultiEventHandling={selectedMultiEventHandling}/>
                        </div>
                    </div>
                    <div className="d-flex flex-row report-filter-item-container">
                        <div className="filter-icon-box d-flex align-items-center justify-content-center">
                            <SummaryDate scale={1.2} />
                        </div>
                        <div className="filter-date-box">
                            <Flatpickr
                                className="form-control"
                                onChange={(e) => { setDateRange(e) }
                                }
                                value={dateRange || ""}
                                options={{
                                    mode: "range",
                                    dateFormat: "Y-m-d",
                                    locale: {
                                        ...calLang
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <Button color="light" onClick={()=>{dispatch(resetEventTableParameters())}}>{t("Common.Btn.Reset")}</Button>
                </div>
                <div className="report-header-btn-container flex-wrap">
                    <Button color="primary" className="report-header-btn" onClick={()=>{setOpenSaveModal(true)}}>{t('PageReport.SaveReportModal.Title')}</Button>
                    <Button color="primary" className="report-header-btn" onClick={async ()=>{
                        try {
                            exportEventReportService(
                                selectedMultiFloorPlan, selectedMultiMonitorType, selectedMultiMonitorStatus, selectedMultiEventHandling, dateRange, i18n.language, selectedReportID !== null?selectedReportID.name:t('PageReport.ExportReportModal.DefaultFileName')
                            );
                        } catch (error) {
                            console.log("Export report");
                            console.log(error);
                        }
                    }}>{t('PageReport.ExportReportModal.Title')}</Button>
                </div>
            </div>
            </div>
            <div className="report-table-container" style={{display: (isLoading?"none":"block")}}>
                <div className="report-table">
                    <Table className="table-nowrap mb-0">
                        <thead className="table-light">
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">{t('PageReport.Table.ID')}</th>
                                <th scope="col">{t('PageReport.Table.StartDate')}</th>
                                <th scope="col">{t('PageReport.Table.StartTime')}</th>
                                <th scope="col">{t('PageReport.Table.Floor')}</th>
                                <th scope="col">{t('PageReport.Table.Room')}</th>
                                <th scope="col">{t('PageReport.Table.Location')}</th>
                                <th scope="col">{t('PageReport.Table.Event')}</th>
                                <th scope="col">{t('PageReport.Table.ReportReason')}</th>
                                <th scope="col">{t('PageReport.Table.Duration')}</th>
                                <th scope="col">{t('PageReport.Table.ArrivalTime')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((i) => {
                                return (
                                    <ReportRow key={i} eventID={i} setOpenEditEventModal={setOpenEditEventModal}/>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </div>
            <div className="report-pagination-container" style={{display: (isLoading?"none":"block")}}>
                <PaginationControl
                    page={parseInt(page)}
                    between={4}
                    total={parseInt(totalEvents)}
                    limit={REPORT_PAGE_SIZE}
                    changePage={(page) => {
                        dispatch(setReportPage(page));
                    }}
                    ellipsis={1}
                />
            </div>
            <ReportSaveModal show={isOpenSaveModal} report={selectedReportID!==null?selectedReportID.name:""} dateRange={dateRange} selectedMultiFloorPlan={selectedMultiFloorPlan} selectedMultiMonitorType={selectedMultiMonitorType} selectedMultiMonitorStatus={selectedMultiMonitorStatus} selectedMultiEventHandling={selectedMultiEventHandling} onCloseClick={(isDone)=>{
                if (isDone) {
                    dispatch(resetSavedEventListTableParameters());
                    props.toggleTag(REPORT_TAB_SAVE_REPORT);
                }
                setOpenSaveModal(false)
            }}/>
             <EventEditModal
                show={isOpenEditEventModal}
                onCloseClick={() => {
                    setOpenEditEventModal(false);
                    dispatch(setEventStatusChangeIDEdit(null));
                }}
            />
        </Row>
    );
}

export default ReportTable;