import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import chroma from "chroma-js";
import colors from "../../assets/scss/config/saas/_colors.scss";
import Select from "react-select";
import { getEventHanldingData, getFloorPlanData, getMonitorStatusData, getMonitorTypeData } from "../../helpers/data_conversion_helper";

export const ALL_FLOORS = "ALL_FLOORS";
export const ALL_LOCATIONS = "ALL_LOCATIONS";
export const ALL_MONITOR_TYPES = "ALL_MONITOR_TYPES";
export const ALL_MONITOR_STATUS = "ALL_MONITOR_STATUS";
export const ALL_EVNET_HANDLING = "ALL_EVNET_HANDLING";
export const ALL_MONITOR_TYPE_STATUS = "ALL_MONITOR_TYPE_STATUS";

export const colourStylesDeviceFilter = (selectColor) => {
    return {
        menu: (styles, state) => ({
            ...styles,
            backgroundColor: colors.white
        }),
        menuList: (styles, state) => ({
            ...styles,
            backgroundColor: colors.white
        }),
        groupHeading: (styles, state) => ({
            ...styles,
            fontSize: '1.1em',
            color: colors.black
        }),
        control: (styles, state) => ({
            ...styles,
            marginLeft: 6,
            marginRight: 6,
            backgroundColor: colors.white,
            border: '1px solid #e5e7eb',
            boxShadow: 'none',
            '&:hover': {
                border: '1px solid #e5e7eb',
            },
            minWidth: 200,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(selectColor);
            return {
                ...styles,
                backgroundColor: color.white,
                '&:hover': {
                    backgroundColor: color.alpha(0.1).css()
                },
            };
        },
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: selectColor,
                marginLeft: 6
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: colors.white,
            fontSize: "1.01em"
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: colors.white,
            ':hover': {
                backgroundColor: selectColor,
                color: colors.white,
            },
        }),
    }
};

const getSelectedOption = (data, allOptions) => {
    let options = [];
    if (data !== undefined && data !== null) {
        data.forEach(d => {
            allOptions.forEach(a => {
                if (a.options !== undefined) {
                    let result = getSelectedOption([d], a.options);
                    options = [...options, ...result];
                }
                else if (a.value === d.value) {
                    options.push(a);
                }
            })
        })
    }
    return options;
}

export const getAllFloorOption = (t) => {
    return { label: t('Common.FloorAll'), value: ALL_FLOORS };
}

export const getAllTypeOption = (t) => {
    return { label: t('Common.MonitorTypeAll'), value: ALL_MONITOR_TYPES };
}

export const getAllStatusOption = (t) => {
    return { label: t('Common.MonitorStatusAll'), value: ALL_MONITOR_STATUS };
}

export const getAllEventHandlingOption = (t) => {
    return { label: t('Common.EventReportReasonAll'), value: ALL_EVNET_HANDLING };
}

const DeviceFilter = (props) => {
    const { t, i18n } = useTranslation();
    const floorplan = props.floorplan;
    const monitorType = props.monitorType;
    const monitorStatus = props.monitorStatus;
    const eventHandling = props.eventHandling;
    const ALL_FLOORS_OPTION = getAllFloorOption(t);
    const ALL_MONITOR_TYPES_OPTION = getAllTypeOption(t);
    const ALL_MONITOR_STATUS_OPTION = getAllStatusOption(t);
    const ALL_EVENT_HANDLING_OPTION = getAllEventHandlingOption(t);
    const selectedMultiFloorPlan = props.selectedMultiFloorPlan;
    const selectedMultiMonitorType = props.selectedMultiMonitorType;
    const selectedMultiMonitorStatus = props.selectedMultiMonitorStatus;
    const selectedMultiEventHandling = props.selectedMultiEventHandling;
    const allFloorPlanOptions = getFloorPlanData(floorplan, t);
    const allMonitorTypeOptions = getMonitorTypeData(monitorType, t);
    const allMonitorStatusOptions = getMonitorStatusData(monitorStatus, t);
    const allEventHandlingOptions = getEventHanldingData(eventHandling, t);

    useEffect(() => {
        if (selectedMultiFloorPlan !== null) {
            props.setSelectedMultiFloorPlan(getSelectedOption(selectedMultiFloorPlan, allFloorPlanOptions));
        }
        if (selectedMultiMonitorType !== null) {
            props.setSelectedMultiMonitorType(getSelectedOption(selectedMultiMonitorType, allMonitorTypeOptions));
        }
        if (selectedMultiMonitorStatus !== null) {
            props.setSelectedMultiMonitorStatus(getSelectedOption(selectedMultiMonitorStatus, allMonitorStatusOptions));
        }
        if (props.selectedMultiEventHandling !== undefined && selectedMultiEventHandling !== null) {
            props.setSelectedMultiEventHandling(getSelectedOption(selectedMultiEventHandling, allEventHandlingOptions));
        }
    }, [i18n.language])

    function handleMultiFloorPlan(value) {
        if (value.length > 0) {
            if (selectedMultiFloorPlan.length === 0) {
                props.setSelectedMultiFloorPlan(value);
            }
            else {
                if (value.length === selectedMultiFloorPlan.length) {
                    return;
                }
                else if (value.length > selectedMultiFloorPlan.length) {
                    if (selectedMultiFloorPlan.length === 1) {
                        if (selectedMultiFloorPlan[0].value === ALL_FLOORS) {
                            props.setSelectedMultiFloorPlan([value[1]]);
                            return;
                        }
                    }
                    if (value[value.length - 1].value === ALL_FLOORS) {
                        props.setSelectedMultiFloorPlan([ALL_FLOORS_OPTION]);
                        return;
                    }
                    else if (value[value.length - 1].value.includes(ALL_LOCATIONS)) {
                        let tempValue = [];
                        value.forEach(v => {
                            if (value[value.length - 1].floor !== v.floor) {
                                tempValue.push(v);
                            }
                        })
                        tempValue.push(value[value.length - 1]);
                        props.setSelectedMultiFloorPlan(tempValue);
                        return;
                    }
                    else {
                        let tempValue = [];
                        selectedMultiFloorPlan.forEach(v => {
                            if (v.value.includes(ALL_LOCATIONS)) {
                                if (v.floor !== value[value.length - 1].floor) {
                                    tempValue.push(v);
                                }
                            }
                            else {
                                tempValue.push(v);
                            }
                        })
                        tempValue.push(value[value.length - 1]);
                        props.setSelectedMultiFloorPlan(tempValue);
                        return;
                    }
                }
                else {
                    props.setSelectedMultiFloorPlan(value);
                    return;
                }
            }
        }

        props.setSelectedMultiFloorPlan(value);
    }

    function handleMultiMonitorType(value) {
        if (value.length > 0) {
            if (selectedMultiMonitorType.length === 1) {
                if (selectedMultiMonitorType[0].value === ALL_MONITOR_TYPES) {
                    if (value.length === 2) {
                        props.setSelectedMultiMonitorType([value[1]]);
                        return;
                    }
                }
                else {
                    if (value.length > 1) {
                        if (value[value.length - 1].value === ALL_MONITOR_TYPES) {
                            props.setSelectedMultiMonitorType([ALL_MONITOR_TYPES_OPTION]);
                            return;
                        }
                    }
                }
            }
            else {
                if (value.length > 1) {
                    if (value[value.length - 1].value === ALL_MONITOR_TYPES) {
                        props.setSelectedMultiMonitorType([ALL_MONITOR_TYPES_OPTION]);
                        return;
                    }
                }
            }
        }

        props.setSelectedMultiMonitorType(value);
    }

    function handleMultiMonitorStatus(value) {
        if (value.length > 0) {
            if (selectedMultiMonitorStatus.length === 0) {
                props.setSelectedMultiMonitorStatus(value);
                return;
            }
            else {
                if (value.length === selectedMultiMonitorStatus.length) {
                    return;
                }
                else if (value.length > selectedMultiMonitorStatus.length) {
                    if (selectedMultiMonitorStatus.length === 1) {
                        if (selectedMultiMonitorStatus[0].value === ALL_MONITOR_STATUS) {
                            props.setSelectedMultiMonitorStatus([value[1]]);
                            return;
                        }
                    }
                    if (value[value.length - 1].value === ALL_MONITOR_STATUS) {
                        props.setSelectedMultiMonitorStatus([ALL_MONITOR_STATUS_OPTION]);
                        return;
                    }
                    else if (value[value.length - 1].value.includes(ALL_MONITOR_TYPE_STATUS)) {
                        let tempValue = [];
                        value.forEach(v => {
                            if (value[value.length - 1].status !== v.status) {
                                tempValue.push(v);
                            }
                        })
                        tempValue.push(value[value.length - 1]);
                        props.setSelectedMultiMonitorStatus(tempValue);
                        return;
                    }
                    else {
                        let tempValue = [];
                        selectedMultiMonitorStatus.forEach(v => {
                            if (v.value.includes(ALL_MONITOR_TYPE_STATUS)) {
                                if (v.status !== value[value.length - 1].status) {
                                    tempValue.push(v);
                                }
                            }
                            else {
                                tempValue.push(v);
                            }
                        })
                        tempValue.push(value[value.length - 1]);
                        props.setSelectedMultiMonitorStatus(tempValue);
                        return;
                    }
                }
                else {
                    props.setSelectedMultiMonitorStatus(value);
                    return;
                }
            }
        }

        props.setSelectedMultiMonitorStatus(value);
    }


    function handleMultiEventHandling(value) {
        if (value.length > 0) {
            if (selectedMultiEventHandling.length === 1) {
                if (selectedMultiEventHandling[0].value === ALL_EVNET_HANDLING) {
                    if (value.length === 2) {
                        props.setSelectedMultiEventHandling([value[1]]);
                        return;
                    }
                }
                else {
                    if (value.length > 1) {
                        if (value[value.length - 1].value === ALL_EVNET_HANDLING) {
                            props.setSelectedMultiEventHandling([ALL_EVENT_HANDLING_OPTION]);
                            return;
                        }
                    }
                }
            }
            else {
                if (value.length > 1) {
                    if (value[value.length - 1].value === ALL_EVNET_HANDLING) {
                        props.setSelectedMultiEventHandling([ALL_EVENT_HANDLING_OPTION]);
                        return;
                    }
                }
            }
        }

        props.setSelectedMultiEventHandling(value);
    }

    return (
        <div className="d-flex flex-row flex-wrap">
            {props.isShowFloor === undefined || props.isShowFloor ?
                <Select
                    value={selectedMultiFloorPlan}
                    isMulti={true}
                    isClearable={true}
                    placeholder={t('PageMain.Device.FilterAreaPlaceholder')}
                    onChange={(value) => {
                        handleMultiFloorPlan(value);
                    }}
                    options={allFloorPlanOptions}
                    styles={colourStylesDeviceFilter(colors.blue)}
                /> : null}
            {props.isShowMonitorType === undefined || props.isShowMonitorType ?
                <Select
                    value={selectedMultiMonitorType}
                    isMulti={true}
                    isClearable={true}
                    placeholder={t('PageMain.Device.FilterMonitorTypePlaceholder')}
                    onChange={(value) => {
                        handleMultiMonitorType(value);
                    }}
                    options={allMonitorTypeOptions}
                    styles={colourStylesDeviceFilter(colors.indigo)}
                /> : null}
            {props.isShowStatusType === undefined || props.isShowStatusType ?
                <Select
                    value={selectedMultiMonitorStatus}
                    isMulti={true}
                    isClearable={true}
                    placeholder={t('PageMain.Device.FilterMonitorStatusPlaceholder')}
                    onChange={(value) => {
                        handleMultiMonitorStatus(value);
                    }}
                    options={allMonitorStatusOptions}
                    styles={colourStylesDeviceFilter(colors.green)}
                /> : null}
            {props.isShowEventHandling === undefined || props.isShowEventHandling ?
                <Select
                    value={selectedMultiEventHandling}
                    isMulti={true}
                    isClearable={true}
                    placeholder={t('PageMain.Device.FilterMonitorStatusPlaceholder')}
                    onChange={(value) => {
                        handleMultiEventHandling(value);
                    }}
                    options={allEventHandlingOptions}
                    styles={colourStylesDeviceFilter(colors.purple)}
                /> : null}
        </div>
    );
};

export default DeviceFilter;