import React, { useState } from "react";
import { Card, CardBody, CardHeader} from "reactstrap";
import { useSelector } from "react-redux";
import ColumnChart from "./ColumnChart";
import { REPORT_PERIOD } from "../../common/constants";
import { useTranslation } from "react-i18next";

function ToiletOTSummaryChart() {
    const { t } = useTranslation();
    const [toiletOTSummaryPeriod, setToiletOTSummaryPeriod] = useState(REPORT_PERIOD.ALL);
    const toiletOTSummaryData = useSelector(state => toiletOTSummaryPeriod === REPORT_PERIOD.M1?state.Summary.toiletOT.M1:toiletOTSummaryPeriod === REPORT_PERIOD.M3?state.Summary.toiletOT.M3:toiletOTSummaryPeriod === REPORT_PERIOD.M6?state.Summary.toiletOT.M6:state.Summary.toiletOT.ALL);
    const xFont = (toiletOTSummaryData.form === REPORT_PERIOD.M1 || toiletOTSummaryData.form === REPORT_PERIOD.M3)?'10px':'12px';
    return (
        <React.Fragment>
            <Card className="summary-card">
                <CardHeader className="summary-card-header">
                    <h4 className="card-title mb-0 summary-title">{t('PageSummary.ToiletUsingOvertime.Title')}</h4>
                    <div className="d-flex flex-row align-items-center">
                        <div className={toiletOTSummaryPeriod === REPORT_PERIOD.M1 ? "summary-period-box summary-period-box-selected" : "summary-period-box"} onClick={() => {
                            setToiletOTSummaryPeriod(REPORT_PERIOD.M1)
                        }}>{t("PageSummary.Period.1M")}</div>
                        <div className={toiletOTSummaryPeriod === REPORT_PERIOD.M3 ? "summary-period-box summary-period-box-selected" : "summary-period-box"} onClick={() => {
                            setToiletOTSummaryPeriod(REPORT_PERIOD.M3)
                        }}>{t("PageSummary.Period.3M")}</div>
                        <div className={toiletOTSummaryPeriod === REPORT_PERIOD.M6 ? "summary-period-box summary-period-box-selected" : "summary-period-box"} onClick={() => {
                            setToiletOTSummaryPeriod(REPORT_PERIOD.M6)
                        }}>{t("PageSummary.Period.6M")}</div>
                        <div className={toiletOTSummaryPeriod === REPORT_PERIOD.ALL ? "summary-period-box summary-period-box-selected" : "summary-period-box"} onClick={() => {
                            setToiletOTSummaryPeriod(REPORT_PERIOD.ALL)
                        }}>{t("PageSummary.Period.ALL")}</div>
                    </div>
                </CardHeader>
                <CardBody>
                    <ColumnChart dataColors='["--vz-danger"]' xData={toiletOTSummaryData.x}                     
                    yData={[{"data": toiletOTSummaryData.y, "name": t('PageSummary.ToiletUsingOvertime.Subtitle')}]}
                    xFont={xFont}/>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default ToiletOTSummaryChart;
