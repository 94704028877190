import { Button, Card, CardBody, CardHeader, UncontrolledTooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { getDangerAlarmBox, getDangerBox, getDisplayFloor, getDisplayLocation, getDisplayRoom, getIdleBox, getNoStatusBox, getNoStatusBoxDescription, getUsingBox } from '../../helpers/display_help';
import {
    AlarmOffIcon,
    AlarmOnIcon,
    CameraIcon,
    EventBedIcon,
    EventFallToiletGridIcon,
    EventIdleToiletIcon,
    EventNomoveToiletGridIcon,
    EventOTToiletGridIcon,
    EventToiletEmptyIcon,
    EventToiletIcon,
    EventUsingToiletGridIcon,
    Monitor24Icon,
    MonitorAppliedRuleIcon,
    MonitorNightIcon,
    MonitorSelfDefinedIcon,
    MultiPplIcon,
    PinIcon,
    SinglePplIcon
} from '../../Components/Common/SVG';
import colors from '../../assets/scss/config/saas/_colors.scss';
import { MONITOR_PERIOD, MONITOR_STATUS, MONITOR_TYPE } from '../../common/constants';
import DeviceTimer from './DeviceTimer';
import { useSelector } from 'react-redux';
import { pinDevice } from '../../services/device';
import { Fragment } from 'react';

const getPinIcon = (data, isGrid = false) => {
    if (!isGrid) {
        let size = 1.2;
        if (data.pin) {
            return <PinIcon scale={size} fill={colors.pinOn} />;
        } else {
            return <PinIcon scale={size} fill={colors.off} />;
        }
    } else {
        let size = 0.97;
        if (data.pin) {
            return <PinIcon scale={size} fill={colors.pinOnGrid} />;
        } else {
            return <PinIcon scale={size} fill={colors.off} />;
        }
    }
};

const getDeviceStatusIcon = (data, t, width = 0, isGrid = false) => {
    if (!isGrid) {
        let item = null;
        if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
            if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.TOILET.IDLE) {
                item = <EventToiletEmptyIcon scale={1.2} fill={data.isConnected ? colors.eventMonitorIdle : colors.off} />;
            } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING || data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT_PAUSED) {
                item = <EventToiletIcon scale={1.2} fill={data.isConnected ? colors.eventMonitorUsing : colors.off} />;
            } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_USING_OT || data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT) {
                item = <EventToiletIcon scale={1.2} fill={data.isConnected ? colors.eventDanger : colors.off} />;
            } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.NOMOVE || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_NOMOVE) {
                item = <EventToiletIcon scale={1.2} fill={data.isConnected ? colors.eventDanger : colors.off} />;
            } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FALL || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_FALL) {
                item = <EventToiletIcon scale={1.2} fill={data.isConnected ? colors.eventDanger : colors.off} />;
            } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.NO_STATUS) {
                item = <EventToiletIcon scale={1.2} fill={colors.off} />;
            }
        } else {
            if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.BED.IDLE) {
                item = <EventBedIcon scale={1.2} fill={data.isConnected ? colors.eventMonitorIdle : colors.off} />;
            } else if (data.currentMonitorStatus === MONITOR_STATUS.BED.NO_STATUS) {
                item = <EventBedIcon scale={1.2} fill={colors.off} />;
            }
        }

        if (data.currentMonitorStatus === null) {
            return (
                <Fragment>
                    <Button className="dash-tooltip-button" id={'dashTooltip-' + data.id}>
                        {item}
                    </Button>
                    <UncontrolledTooltip style={{ backgroundColor: '#666666' }} placement="top" target={'dashTooltip-' + data.id}>
                        {t('Common.MonitorStatusType.NULL')}
                    </UncontrolledTooltip>
                </Fragment>
            );
        }
        return (
            <Fragment>
                <Button className="dash-tooltip-button" id={'dashTooltip-' + data.id}>
                    {item}
                </Button>
                <UncontrolledTooltip style={{ backgroundColor: '#666666' }} placement="top" target={'dashTooltip-' + data.id}>
                    {t('Common.MonitorStatusType.' + data.currentMonitorStatus)}
                </UncontrolledTooltip>
            </Fragment>
        );
    } else {
        let item = null;
        let scale = (1.5 * width) / 350;
        if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
            if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.IDLE) {
                item = <EventIdleToiletIcon scale={scale} fill={data.isConnected ? colors.eventMonitorIdle : colors.off} />;
            } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING || data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT_PAUSED) {
                item = <EventUsingToiletGridIcon scale={scale} fill={data.isConnected ? colors.eventMonitorUsing : colors.off} />;
            } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_USING_OT || data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT) {
                item = <EventOTToiletGridIcon scale={scale} fill={data.isConnected ? colors.eventDanger : colors.off} fillIcon={data.isConnected ? (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_USING_OT ? colors.off : colors.white) : colors.white} />;
            } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.NOMOVE || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_NOMOVE) {
                item = <EventNomoveToiletGridIcon scale={scale} fill={data.isConnected ? colors.eventDanger : colors.off} fillIcon={data.isConnected ? (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_NOMOVE ? colors.off : colors.white) : colors.white} />;
            } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FALL || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_FALL) {
                item = <EventFallToiletGridIcon scale={scale} fill={data.isConnected ? colors.eventDanger : colors.off} fillIcon={data.isConnected ? (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_FALL ? colors.off : colors.white) : colors.white} />;
            } else if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.TOILET.NO_STATUS) {
                item = <EventIdleToiletIcon scale={scale} fill={colors.off} />;
            }
        } else {
            if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.BED.IDLE) {
                item = <EventBedIcon scale={1.2} fill={data.isConnected ? colors.eventMonitorIdle : colors.off} />;
            } else if (data.currentMonitorStatus === MONITOR_STATUS.BED.NO_STATUS) {
                item = <EventBedIcon scale={1.2} fill={colors.off} />;
            }
        }

        if (data.currentMonitorStatus === null) {
            return (
                <Fragment>
                    <Button className="dash-tooltip-button" id={'dashTooltip-' + data.id}>
                        {item}
                    </Button>
                    <UncontrolledTooltip style={{ backgroundColor: '#666666' }} placement="top" target={'dashTooltip-' + data.id}>
                        {t('Common.MonitorStatusType.NULL')}
                    </UncontrolledTooltip>
                </Fragment>
            );
        }
        return (
            <Fragment>
                <Button className="dash-tooltip-button" id={'dashTooltip-' + data.id}>
                    {item}
                </Button>
                <UncontrolledTooltip style={{ backgroundColor: '#666666' }} placement="top" target={'dashTooltip-' + data.id}>
                    {t('Common.MonitorStatusType.' + data.currentMonitorStatus)}
                </UncontrolledTooltip>
            </Fragment>
        );
    }
};

const getDeviceConnectionIcon = (data, t, isGrid) => {
    if (!isGrid) {
        let size = 1.05;
        if (data.isConnected) {
            if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
                return (
                    <Fragment>
                        <Button className="dash-tooltip-button dash-device-card-text-connection-icon" id={'dashTooltip-connection-' + data.id}>
                            <CameraIcon scale={size} fill={colors.cameraNormal} />
                        </Button>
                        <UncontrolledTooltip style={{ backgroundColor: '#666666' }} placement="top" target={'dashTooltip-connection-' + data.id}>
                            {t('Common.DeviceConnection.Normal')}
                        </UncontrolledTooltip>
                    </Fragment>
                );
            } else {
                return (
                    <Fragment>
                        <Button className="dash-tooltip-button dash-device-card-text-connection-icon" id={'dashTooltip-connection-' + data.id}>
                            <CameraIcon scale={size} fill={colors.cameraNormal} />
                        </Button>
                        <UncontrolledTooltip style={{ backgroundColor: '#666666' }} placement="top" target={'dashTooltip-connection-' + data.id}>
                            {t('Common.DeviceConnection.Normal')}
                        </UncontrolledTooltip>
                    </Fragment>
                );
            }
        } else {
            return (
                <Fragment>
                    <Button className="dash-tooltip-button dash-device-card-text-connection-icon" id={'dashTooltip-connection-' + data.id}>
                        <CameraIcon scale={size} fill={colors.cameraError} />
                    </Button>
                    <UncontrolledTooltip style={{ backgroundColor: '#666666' }} placement="top" target={'dashTooltip-connection-' + data.id}>
                        {t('Common.DeviceConnection.Stopped')}
                    </UncontrolledTooltip>
                </Fragment>
            );
        }
    } else {
        let size = 0.85;
        if (data.isConnected) {
            if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
                return (
                    <Fragment>
                        <Button className="dash-tooltip-button dash-device-card-text-connection-icon" id={'dashTooltip-connection-' + data.id}>
                            <CameraIcon scale={size} fill={colors.cameraNormalGrid} />
                        </Button>
                        <UncontrolledTooltip style={{ backgroundColor: '#666666' }} placement="top" target={'dashTooltip-connection-' + data.id}>
                            {t('Common.DeviceConnection.Normal')}
                        </UncontrolledTooltip>
                    </Fragment>
                );
            } else {
                return (
                    <Fragment>
                        <Button className="dash-tooltip-button dash-device-card-text-connection-icon" id={'dashTooltip-connection-' + data.id}>
                            <CameraIcon scale={size} fill={colors.cameraNormalGrid} />
                        </Button>
                        <UncontrolledTooltip style={{ backgroundColor: '#666666' }} placement="top" target={'dashTooltip-connection-' + data.id}>
                            {t('Common.DeviceConnection.Normal')}
                        </UncontrolledTooltip>
                    </Fragment>
                );
            }
        } else {
            return (
                <Fragment>
                    <Button className="dash-tooltip-button dash-device-card-text-connection-icon" id={'dashTooltip-connection-' + data.id}>
                        <CameraIcon scale={size} fill={colors.cameraError} />
                    </Button>
                    <UncontrolledTooltip style={{ backgroundColor: '#666666' }} placement="top" target={'dashTooltip-connection-' + data.id}>
                        {t('Common.DeviceConnection.Stopped')}
                    </UncontrolledTooltip>
                </Fragment>
            );
        }
    }
};

const getMonitorPeriod = (data, t) => {
    let size = 1.4;
    let fill = data.isConnected ? colors.blue : colors.off;
    let item = null;
    if (data.monitorPeriod === MONITOR_PERIOD['24hours']) {
        item = <Monitor24Icon scale={size} fill={fill} />;
    } else if (data.monitorPeriod === MONITOR_PERIOD.MidNight) {
        item = <MonitorNightIcon scale={size} fill={fill} />;
    } else if (data.monitorPeriod === MONITOR_PERIOD.SelfDefined) {
        item = <MonitorSelfDefinedIcon scale={size} fill={fill} />;
    } else if (data.monitorPeriod === MONITOR_PERIOD.AppliedRule) {
        item = <MonitorAppliedRuleIcon scale={size} fill={fill} />;
    } else {
        item = <div></div>;
    }

    return (
        <Fragment>
            <Button className="dash-tooltip-button" id={'dashTooltip-monitor-period-' + data.id}>
                {item}
            </Button>
            <UncontrolledTooltip style={{ backgroundColor: '#666666' }} placement="top" target={'dashTooltip-monitor-period-' + data.id}>
                {t('Common.MonitorPeriod.' + data.monitorPeriod)}
            </UncontrolledTooltip>
        </Fragment>
    );
};

const getCarerName = data => {
    if (data.carerName !== null) {
        return <div className="dash-device-card-text-off">{data.carerName}</div>;
    } else {
        if (data.isConnected) {
            if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
                if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.TOILET.IDLE) {
                    return <div className="dash-device-card-text-off">-</div>;
                } else {
                    return <div>-</div>;
                }
            } else {
                return <div></div>;
            }
        } else {
            return <div className="dash-device-card-text-off">-</div>;
        }
    }
};

const getFloor = (data, t) => {
    let floor = getDisplayFloor(data.floorName, t);
    if (data.isConnected) {
        if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
            if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.TOILET.IDLE) {
                return <div className="dash-device-card-text-off">{floor}</div>;
            } else {
                return <div>{floor}</div>;
            }
        } else {
            if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.BED.IDLE) {
                return <div className="dash-device-card-text-off">{floor}</div>;
            } else {
                return <div>{floor}</div>;
            }
        }
    } else {
        return <div className="dash-device-card-text-off">{floor}</div>;
    }
};

const getRoom = (data, t) => {
    let room = getDisplayRoom(data, t);
    if (data.isConnected) {
        if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
            if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.TOILET.IDLE) {
                return <div className="dash-device-card-text-off">{room}</div>;
            } else {
                return <div>{room}</div>;
            }
        } else {
            if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.BED.IDLE) {
                return <div className="dash-device-card-text-off">{room}</div>;
            } else {
                return <div>{room}</div>;
            }
        }
    } else {
        return <div className="dash-device-card-text-off">{room}</div>;
    }
};

const getLocation = (data, t, isGrid = false) => {
    let location = getDisplayLocation(data, t);
    if (!isGrid) {
        if (data.isConnected) {
            if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
                if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.TOILET.IDLE) {
                    return <div className="dash-device-card-text-off">{location}</div>;
                } else {
                    return <div>{location}</div>;
                }
            } else {
                if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.BED.IDLE) {
                    return <div className="dash-device-card-text-off">{location}</div>;
                } else {
                    return <div>{location}</div>;
                }
            }
        } else {
            return <div className="dash-device-card-text-off">{location}</div>;
        }
    } else {
        if (data.isConnected) {
            if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
                if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.TOILET.IDLE) {
                    return getIdleBox(location);
                } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING || data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT_PAUSED) {
                    return getUsingBox(location);
                } else if (
                    data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_USING_OT ||
                    data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT ||
                    data.currentMonitorStatus === MONITOR_STATUS.TOILET.NOMOVE ||
                    data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_NOMOVE ||
                    data.currentMonitorStatus === MONITOR_STATUS.TOILET.FALL ||
                    data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_FALL
                ) {
                    return getDangerBox(location);
                } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.NO_STATUS) {
                    return getNoStatusBox(location);
                }
            } else {
                if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.BED.IDLE) {
                    return getIdleBox(location);
                } else {
                    return <div>{location}</div>;
                }
            }
        } else {
            return getNoStatusBox(location);
        }
    }
};

const getDescription = (data, isMQConnected, isSocketConnected, t, isGrid = false) => {
    if (!isGrid) {
        if (data.isConnected && isMQConnected && isSocketConnected) {
            if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
                if (data.currentMonitorStatus === null) {
                    return <div className="dash-device-card-text-toilet-idle">-</div>;
                } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.IDLE) {
                    return <div className="dash-device-card-text-toilet-idle">{t('Common.MonitorStatusTypeShortForm.' + data.currentMonitorStatus)}</div>;
                } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING || data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT_PAUSED) {
                    return <div className="dash-device-card-text-toilet-using">{t('Common.MonitorStatusTypeShortForm.' + data.currentMonitorStatus)}</div>;
                } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT || data.currentMonitorStatus === MONITOR_STATUS.TOILET.NOMOVE || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FALL) {
                    return <div className="dash-danger-status-box dash-danger-status-box-text">{t('Common.MonitorStatusTypeShortForm.' + data.currentMonitorStatus)}</div>;
                } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_USING_OT || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_NOMOVE || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_FALL) {
                    return <div className="dash-device-card-text-toilet-danger">{t('Common.MonitorStatusTypeShortForm.' + data.currentMonitorStatus)}</div>;
                } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.NO_STATUS) {
                    return <div className="dash-device-card-text-off">{t('Common.Updating')}</div>;
                } else {
                    return <div></div>;
                }
            } else {
                if (data.currentMonitorStatus === null) {
                    return <div className="dash-device-card-text-toilet-idle">-</div>;
                } else if (data.currentMonitorStatus === MONITOR_STATUS.BED.IDLE) {
                    return <div className="dash-device-card-text-toilet-idle">{t('Common.MonitorStatusTypeShortForm.' + data.currentMonitorStatus)}</div>;
                } else if (data.currentMonitorStatus === MONITOR_STATUS.BED.NO_STATUS) {
                    return <div className="dash-device-card-text-off">{t('Common.Updating')}</div>;
                } else {
                    return <div></div>;
                }
            }
        } else {
            return <div className="dash-device-card-text-nosignal">{t('Common.Connection.Off')}</div>;
        }
    } else {
        if (data.isConnected && isMQConnected && isSocketConnected) {
            if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
                if (data.currentMonitorStatus === null) {
                    return getNoStatusBox('-');
                } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.IDLE) {
                    return getIdleBox(t('Common.MonitorStatusTypeShortForm.' + data.currentMonitorStatus));
                } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING || data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT_PAUSED) {
                    return getUsingBox(t('Common.MonitorStatusTypeShortForm.' + data.currentMonitorStatus));
                } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT || data.currentMonitorStatus === MONITOR_STATUS.TOILET.NOMOVE || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FALL) {
                    return getDangerAlarmBox(t('Common.MonitorStatusTypeShortForm.' + data.currentMonitorStatus));
                } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_USING_OT || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_NOMOVE || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_FALL) {
                    return getDangerBox(t('Common.MonitorStatusTypeShortForm.' + data.currentMonitorStatus));
                } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.NO_STATUS) {
                    return getNoStatusBoxDescription(t('Common.MonitorStatusTypeShortForm.' + data.currentMonitorStatus));
                } else {
                    return <div></div>;
                }
            } else {
                if (data.currentMonitorStatus === null) {
                    return <div className="dash-device-card-text-toilet-idle">-</div>;
                } else if (data.currentMonitorStatus === MONITOR_STATUS.BED.IDLE) {
                    return <div className="dash-device-card-text-toilet-idle">{t('Common.MonitorStatusTypeShortForm.' + data.currentMonitorStatus)}</div>;
                } else if (data.currentMonitorStatus === MONITOR_STATUS.BED.NO_STATUS) {
                    return <div className="dash-device-card-text-off">{t('Common.Updating')}</div>;
                } else {
                    return <div></div>;
                }
            }
        } else {
            return getNoStatusBox(t('Common.Connection.Off'));
        }
    }
};

const getAlarm = data => {
    if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FALL || data.currentMonitorStatus === MONITOR_STATUS.TOILET.NOMOVE || data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT) {
        return <AlarmOnIcon scale={1.3} fill={colors.eventAlarmOn} />;
    } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_FALL || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_NOMOVE || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_USING_OT) {
        return <AlarmOffIcon scale={1.3} fill={colors.eventAlarmOff} />;
    } else {
        return null;
    }
};

const getNoOfPplIcon = data => {
    if (data.noOfPpl > 0 && data.isConnected) {
        if (
            data.currentMonitorStatus === MONITOR_STATUS.TOILET.FALL ||
            data.currentMonitorStatus === MONITOR_STATUS.TOILET.NOMOVE ||
            data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT ||
            data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_FALL ||
            data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_NOMOVE ||
            data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_USING_OT
        ) {
            if (data.noOfPpl === 1) {
                return <SinglePplIcon scale={0.7} fill={colors.eventAlarmOn} />;
            } else {
                return <MultiPplIcon scale={0.7} fill={colors.eventAlarmOn} />;
            }
        } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING) {
            if (data.noOfPpl === 1) {
                return <SinglePplIcon scale={0.7} fill={colors.eventMonitorUsing} />;
            } else {
                return <MultiPplIcon scale={0.7} fill={colors.eventMonitorUsing} />;
            }
        }
    }
    return null;
};

const getHandleButton = (data, t, openConfirmAlert) => {
    if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FALL || data.currentMonitorStatus === MONITOR_STATUS.TOILET.NOMOVE || data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT) {
        return (
            <Button
                className="btn-sm dash-event-btn"
                color="primary"
                onClick={() => {
                    openConfirmAlert(data.id, true);
                }}>
                {' '}
                {t('PageMain.Device.HandledBtn.No')}{' '}
            </Button>
        );
    } else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_FALL || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_NOMOVE || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_USING_OT) {
        return (
            <Button className="btn-sm dash-event-btn" color="primary" onClick={() => {}} disabled>
                {' '}
                {t('PageMain.Device.HandledBtn.Yes')}{' '}
            </Button>
        );
    }
};

const getFollowUpButton = (data, t, openConfirmAlert) => {
    if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_USING_OT || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_FALL || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_NOMOVE) {
        return (
            <Button
                className="btn-sm dash-event-btn"
                color="primary"
                onClick={() => {
                    openConfirmAlert(data.id, false);
                }}>
                {' '}
                {t('PageMain.Device.FollowUpBtn.Yes')}{' '}
            </Button>
        );
    }
    return <div></div>;
};

const getEditIcon = data => {
    if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
        return <div></div>;
    } else {
        //return <EditIcon size={size} fill={colors.offText} />
        return <div></div>;
    }
};

function DeviceCard(props) {
    const { t } = useTranslation();
    const deviceID = props.data;
    const openConfirmAlert = props.openConfirmAlert;
    const data = useSelector(state => state.Device.devices[deviceID]);
    const isMQConnected = useSelector(state => state.Device.isMQConnected);
    const isSocketConnected = useSelector(state => state.Device.isSocketConnected);

    const pinRecord = (monitorLocationDeviceID, isPin) => {
        pinDevice(monitorLocationDeviceID, isPin);
    };

    if (data.isShow === undefined || data.isShow) {
        if (props.isListView) {
            return (
                <Card className="dash-device-card">
                    <CardBody className="dash-device-cardbody">
                        <div className="d-flex flex-row flex-wrap dash-device-container">
                            <div
                                style={{ flex: 1, minWidth: 30 }}
                                className="dash-device-card-icon d-flex flex-column align-items-center justify-content-center"
                                onClick={() => {
                                    pinRecord(data.id, !data.pin);
                                }}>
                                {getPinIcon(data)}
                            </div>
                            <div style={{ flex: 1, minWidth: 30 }} className="dash-device-card-icon d-flex flex-column align-items-center justify-content-center">
                                {getDeviceStatusIcon(data, t)}
                            </div>
                            <div style={{ flex: 1, minWidth: 30 }} className="dash-device-card-icon d-flex flex-column align-items-center justify-content-center">
                                {getDeviceConnectionIcon(data, t)}
                            </div>
                            <div style={{ flex: 1, minWidth: 30, marginTop: -5 }} className="dash-device-card-icon d-flex flex-column align-items-center justify-content-center">
                                {getMonitorPeriod(data, t)}
                            </div>
                            <div style={{ flex: 1, minWidth: 100 }} className="dash-device-card-text d-flex flex-column align-items-center justify-content-center">
                                {getFloor(data, t)}
                            </div>
                            <div style={{ flex: 1, minWidth: 100 }} className="dash-device-card-text d-flex flex-column align-items-center justify-content-center">
                                {getRoom(data, t)}
                            </div>
                            <div style={{ flex: 1, minWidth: 100 }} className="dash-device-card-text d-flex flex-column align-items-center justify-content-center">
                                {getLocation(data, t)}
                            </div>
                            <div style={{ flex: 1, minWidth: 100 }} className="dash-device-card-text d-flex flex-column align-items-center justify-content-center">
                                {getCarerName(data)}
                            </div>
                            <div style={{ flex: 1, minWidth: 140 }} className="dash-device-card-text d-flex flex-column align-items-center justify-content-center">
                                {getDescription(data, isMQConnected, isSocketConnected, t)}
                            </div>
                            <div style={{ flex: 1, minWidth: 100 }} className="dash-device-card-text d-flex flex-column align-items-center justify-content-center">
                                <DeviceTimer data={data} />
                            </div>
                            <div style={{ flex: 1, minWidth: 100 }} className="dash-device-card-text d-flex flex-column align-items-center justify-content-center">
                                {getAlarm(data)}
                            </div>
                            <div style={{ flex: 1, minWidth: 100 }} className="dash-device-card-text d-flex flex-column align-items-center justify-content-center">
                                {getHandleButton(data, t, openConfirmAlert)}
                            </div>
                            <div style={{ flex: 1, minWidth: 100 }} className="dash-device-card-text d-flex flex-column align-items-center justify-content-center">
                                {getFollowUpButton(data, t, openConfirmAlert)}
                            </div>
                            <div style={{ flex: 1, minWidth: 100 }} className="dash-device-card-icon d-flex flex-column align-items-center justify-content-center">
                                {getEditIcon(data)}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            );
        } else {
            let room = getDisplayRoom(data, t);
            if (room !== '-') {
                room = ' - ' + room;
            } else {
                room = '';
            }
            let width = props.cardWidth - 8;
            let cardBgColor = colors.white;
            if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_FALL || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_NOMOVE || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_USING_OT) {
                cardBgColor = colors.off;
            }
            return (
                <Card style={{ width: width, height: 206, margin: 6 }} className="dash-device-card-grid">
                    <CardHeader className="dash-device-card-text-grid-card-header">
                        <div className="d-flex flex-row justify-content-between w-100">
                            <div style={{ flex: 1 }} className="d-flex flex-row justify-content-space align-items-center">
                                <div
                                    style={{ marginTop: -1 }}
                                    onClick={() => {
                                        pinRecord(data.id, !data.pin);
                                    }}>
                                    {getPinIcon(data, true)}
                                </div>
                                <div style={{ marginTop: 1 }}>{getDeviceConnectionIcon(data, t, true)}</div>
                            </div>
                            <div className="d-flex flex-row justify-content-space dash-device-card-text-grid-card-header-title">
                                {getDisplayFloor(data.floorName, t)}
                                {room}
                            </div>
                            <div style={{ flex: 1 }}></div>
                        </div>
                    </CardHeader>
                    <CardBody style={{ paddingLeft: 0, paddingRight: 10, backgroundColor: cardBgColor }}>
                        <div className="d-flex flex-row justify-content-space w-100 h-100">
                            <div style={{ flex: 1 }} className="d-flex flex-column justify-content-start align-items-center h-100">
                                <div style={{ flex: 2 }} className="mb-1 w-100 d-flex flex-column justify-content-center align-items-center">
                                    {getDeviceStatusIcon(data, t, width, true)}
                                </div>
                                <div style={{ flex: 1, width: '100%', paddingLeft: 0 }} className="d-flex flex-row justify-content-center align-items-center">
                                    <div style={{ flex: 1 }}>{getNoOfPplIcon(data)}</div>
                                    <div style={{ flex: 1 }}>{getAlarm(data)}</div>
                                    <div style={{ flex: 1 }}></div>
                                </div>
                            </div>
                            <div style={{ flex: 2 }} className="d-flex flex-column justify-content-space w-100">
                                <div>{getLocation(data, t, true)}</div>
                                <div className="d-flex flex-row justify-content-space">
                                    {getDescription(data, isMQConnected, isSocketConnected, t, true)}
                                    <DeviceTimer data={data} isGrid={true} />
                                </div>
                                <div className="d-flex flex-row justify-content-space">
                                    <div className="dash-device-card-text-grid">{getHandleButton(data, t, openConfirmAlert)}</div>
                                    <div className="dash-device-card-text-grid">{getFollowUpButton(data, t, openConfirmAlert)}</div>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            );
        }
    } else {
        return null;
    }
}

export default DeviceCard;
