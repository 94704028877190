import { APIClient } from "../helpers/api_helper";
import { GET_ALL_DEVICE, GET_ALL_DEVICE_CURRENT_STATUS, HANDLED_EVENT_DEVICE, HANDLE_EVENT_DEVICE, LIST_STATUS_DEVICE, POST_DEVICE_CONFIG, POST_PIN_DEVICE } from "../helpers/url_helper";

const api = new APIClient();

export const getDevices = async () => {
    return await api.get(GET_ALL_DEVICE);
};

export const getDevicesCurrentStatus = async () => {
    return await api.get(GET_ALL_DEVICE_CURRENT_STATUS);
};

export const pinDevice = async (id, isPin) => {
    return await api.create(POST_PIN_DEVICE, { id: id, isPin: isPin });
};

export const userHandleEvent = async (id, statusChangeID) => {
    console.log("id: " + id + " | statusChangeID: " + statusChangeID);
    return await api.create(HANDLE_EVENT_DEVICE, { id: id, statusChangeID: statusChangeID });
};

export const userHandledEvent = async (id, statusChangeID, reason, previousStatus) => {
    console.log("userHandledEvent id: " + id + " | statusChangeID: " + statusChangeID + " | reason: " + reason + " | previousStatus: " + previousStatus);
    return await api.create(HANDLED_EVENT_DEVICE, { id: id, statusChangeID: statusChangeID, reason: reason, status: previousStatus });
};

export const getDeviceStatus = async () => {
    return await api.get(LIST_STATUS_DEVICE);
};

export const updateDeviceConfig = async (deviceID, configID, settingID, value) => {
    console.log("settingID: " + settingID + " | value: " + value);
    return await api.create(POST_DEVICE_CONFIG, { deviceID: deviceID, configID: configID, settingID: settingID, value: value });
};
