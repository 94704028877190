import React, { useState } from "react";
import { Card, CardBody, CardHeader} from "reactstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UpdateDountChart from "./UpdateDountChart";

function ToiletUsingAverageTimeSummaryChart() {
    const { t } = useTranslation();
    const toiletSummaryData = useSelector((state => state.Summary.toiletUsingAverageTime));
    return (
        <React.Fragment>
            <Card className="summary-card">
                <CardHeader className="summary-card-header">
                    <h4 className="card-title mb-0 summary-title">{t('PageSummary.UsingPeriod.Title')}</h4>
                </CardHeader>
                <CardBody className="p-4">
                    <UpdateDountChart dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger"]' xData={toiletSummaryData.x} yData={[t('PageSummary.UsingPeriod.lt5'),t('PageSummary.UsingPeriod.5_10'), t('PageSummary.UsingPeriod.10_15'), t('PageSummary.UsingPeriod.gt15') ]}/>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default ToiletUsingAverageTimeSummaryChart;
