import { APIClient } from "../helpers/api_helper";
import { GET_DEVELOPER_DEVICES, GET_DEVICE_CONFIG } from "../helpers/url_helper";

const api = new APIClient();

export const getDevicesByDeveloper = async () => {
    return await api.get(GET_DEVELOPER_DEVICES);
};

export const getDeviceConfig = async (deviceID) => {
    let result = await api.get(GET_DEVICE_CONFIG + "/" + deviceID);
    console.log("getDeviceConfig");
    console.log(result);
    return result;
};

