import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  floorplan: [],
  monitorType: [],
  monitorStatus: [],
  careType: [],
  monitorPeriod: [],
  appliedRules: [],
  reportMonitorStatus: [],
  eventReportReason: {},
  error: {}
};

const staticDataSlice = createSlice({
  name: 'staticData',
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
    },
    getFloorPlanSuccess(state, action) {
      state.floorplan = action.payload.data;
    },
    getMonitorTypeSuccess(state, action) {
      state.monitorType = action.payload.data;
    },
    getMonitorStatusSuccess(state, action) {
      state.monitorStatus = action.payload.data;
    },
    getCareTypeSuccess(state, action) {
      state.careType = action.payload.data;
    },
    getMonitorPeriodSuccess(state, action) {
      state.monitorPeriod = action.payload.data;
    },
    getAppliedRulesSuccess(state, action) {
      state.appliedRules = action.payload.data;
    },
    getReportMonitorStatusSuccess(state, action) {
      state.reportMonitorStatus = action.payload.data;
    },
    getEventReportReasonSuccess(state, action) {
      state.eventReportReason = action.payload.data;
    },
  },
  extraReducers: (builder) => {
  }
});

export const {
  apiError,
  getFloorPlanSuccess,
  getMonitorTypeSuccess,
  getMonitorStatusSuccess,
  getCareTypeSuccess,
  getMonitorPeriodSuccess,
  getAppliedRulesSuccess,
  getReportMonitorStatusSuccess,
  getEventReportReasonSuccess
} = staticDataSlice.actions

export default staticDataSlice.reducer;