import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";

// Static Data
import StaticDataReducer from "./staticData/reducer";
// Device
import DeviceReducer from "./device/reducer";
// Event
import EventReducer from "./event/reducer";
// Summary
import SummaryReducer from "./summary/reducer";

import DeviceStatusReducer from "./status/reducer";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    Account: AccountReducer,
    ForgetPassword: ForgetPasswordReducer,
    Profile: ProfileReducer,
    Device: DeviceReducer,
    StaticData: StaticDataReducer,
    Event: EventReducer,
    Summary: SummaryReducer,
    DeviceStatus: DeviceStatusReducer,
});

export default rootReducer;