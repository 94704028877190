import React from "react";
import { MONITOR_STATUS, MONITOR_TYPE } from "../../common/constants";
import { useSelector } from "react-redux";
import { getDangerBox, getDisplayDatetimeFromTimestamp, getIdleBox, getNoStatusBox, getUsingBox } from "../../helpers/display_help";


const DeviceTimer = ({ data, isGrid }) => {
    const timer = useSelector((state => state.Device.timer[data.id]));
    const timerString = timer !== undefined ? getDisplayDatetimeFromTimestamp(timer) : "-";

    if (isGrid) {
        if (!data.isConnected) {
            return getNoStatusBox(timerString);
        }
        else {
            if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
                if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.TOILET.IDLE) {
                    return getIdleBox(timerString);
                }
                else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING || data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT_PAUSED) {
                    return getUsingBox(timerString);
                }
                else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_USING_OT || data.currentMonitorStatus === MONITOR_STATUS.TOILET.NOMOVE || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_NOMOVE || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FALL || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_FALL) {
                    return getDangerBox(timerString);
                }
                else {
                    return getNoStatusBox("-");
                }
            }
            else {
                if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.BED.IDLE) {
                    return getIdleBox(timerString);
                }
                else {
                    return getNoStatusBox("-");
                }
            }
        }
    }

    if (!data.isConnected) {
        return <div className="dash-device-card-text-nosignal">{timerString}</div>
    }
    else {
        if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
            if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.TOILET.IDLE) {
                return <div className="dash-device-card-text-toilet-idle">{timerString}</div>;
            }
            else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING) {
                return <div className="dash-device-card-text-toilet-using">{timerString}</div>;
            }
            else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_USING_OT || data.currentMonitorStatus === MONITOR_STATUS.TOILET.NOMOVE || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_NOMOVE || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FALL || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_FALL) {
                return <div className="dash-device-card-text-toilet-danger">{timerString}</div>;
            }
            else {
                return <div className="dash-device-card-text-toilet-idle">-</div>
            }
        }
        else {
            if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.BED.IDLE) {
                return <div className="dash-device-card-text-toilet-idle">{timerString}</div>;
            }
            else {
                return <div className="dash-device-card-text-toilet-idle">-</div>
            }
        }
    }
}

export default DeviceTimer;