import { Card, CardBody } from "reactstrap";
import DashboardSectionHeader from "./DashboardSectionHeader";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import HistoryCard from "./HistoryCard";
import { useEffect, useState } from "react";
import EventEditModal from "../../Components/Common/EventEditModal";
import { ArrowDownIcon, ArrowUpIcon } from "../../Components/Common/SVG";
import { getAllDeviceStatus } from "../../slices/status/thunk";
import { setEventStatusChangeIDEdit } from "../../slices/event/reducer";


function HistorySection(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const statusID = useSelector((state => state.DeviceStatus.id));
    const [isOpenEditEventModal, setOpenEditEventModal] = useState(false);
    const isMQConnected = useSelector(state => state.Device.isMQConnected);
    const isSocketConnected = useSelector(state => state.Device.isSocketConnected);
    const { isEventShown, setEventShown } = props;
    
    useEffect(() => {
        if (isMQConnected && isSocketConnected) {
            dispatch(getAllDeviceStatus());
        }
    }, [isMQConnected, isSocketConnected]);

    return (
        <Card className="section-card" style={{height:isEventShown?"100%":"auto"}}>
            <CardBody className="section-cardbody">
                <div className="d-flex flex-column section-container">
                    <div className="d-flex flex-row justify-content-between">
                        <DashboardSectionHeader title={t('PageMain.History.Title')}  isShown={false}/>
                        <div className="d-flex flex-row justify-content-center align-items-center dash-history-collapse-btn" onClick={()=>{setEventShown(!isEventShown)}}>
                        {isEventShown?<ArrowDownIcon scale={1} />:<ArrowUpIcon scale={1}/>}
                        </div>
                        
                    </div>
                    <div className="card-container">
                    {isEventShown && statusID !== undefined && statusID.map((data) => {
                        return (
                            <HistoryCard key={data} data={data} setOpenEditEventModal={setOpenEditEventModal}/>
                        );
                    })}
                    </div>
                </div>
            </CardBody>
            <EventEditModal
                show={isOpenEditEventModal}
                onCloseClick={() => {
                    setOpenEditEventModal(false);
                    dispatch(setEventStatusChangeIDEdit(null));
                }}
            />
        </Card>
    );
}

export default HistorySection;