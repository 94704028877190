import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  toiletFall: {
    M1: {
      x:[],
      y:[]
    },
    M3: {
      x:[],
      y:[]
    },
    M6: {
      x:[],
      y:[]
    },
    ALL: {
      x:[],
      y:[]
    }
  },
  toiletOT: {
    M1: {
      x:[],
      y:[]
    },
    M3: {
      x:[],
      y:[]
    },
    M6: {
      x:[],
      y:[]
    },
    ALL: {
      x:[],
      y:[]
    }
  },
  toiletUsingAverageTime: {
    x:[0, 0, 0, 0]
  },
  toiletUsingFrequence: {
    devices:[],
    freqInfo: {},
    deviceInfo: {},
    category: [],
    form: null,
  },
  toiletArrival: {
    M1: {
      x:[],
      y1:[],
      y2:[]
    },
    M3: {
      x:[],
      y1:[],
      y2:[]
    },
    M6: {
      x:[],
      y1:[],
      y2:[]
    },
    ALL: {
      x:[],
      y1:[],
      y2:[]
    }
  },
  bedFall: {
    M1: {
      x:[],
      y:[]
    },
    M3: {
      x:[],
      y:[]
    },
    M6: {
      x:[],
      y:[]
    },
    ALL: {
      x:[],
      y:[]
    }
  },
  bedArrival: {
    M1: {
      x:[],
      y1:[],
      y2:[]
    },
    M3: {
      x:[],
      y1:[],
      y2:[]
    },
    M6: {
      x:[],
      y1:[],
      y2:[]
    },
    ALL: {
      x:[],
      y1:[],
      y2:[]
    }
  },
  selectedMultiFloorPlan: null,
  error: {},
};

const summarySlice = createSlice({
  name: 'Summary',
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
    },
    getToiletFallSummarySuccess(state, action) {
      console.log(action);
      return {
        ...state,
        toiletFall: {
          ...action.payload.data
        }
      }
    },
    getToiletOTSummarySuccess(state, action) {
      console.log(action);
      return {
        ...state,
        toiletOT: {
          ...action.payload.data
        }
      }
    },
    getToiletArrivalSummarySuccess(state, action) {
      console.log(action);
      return {
        ...state,
        toiletArrival: {
          ...action.payload.data
        }
      }
    },
    getBedArrivalSummarySuccess(state, action) {
      console.log(action);
      return {
        ...state,
        bedArrival: {
          ...action.payload.data
        }
      }
    },
    getBedFallSummarySuccess(state, action) {
      console.log(action);
      return {
        ...state,
        bedFall: {
          ...action.payload.data
        }
      }
    },
    getToiletUsingAverageTimeSummarySuccess(state, action) {
      console.log(action);
      return {
        ...state,
        toiletUsingAverageTime: {
          ...action.payload.data
        }
      }
    },
    getToiletUsingFrequenceSummarySuccess(state, action) {
      console.log(action);
      return {
        ...state,
        toiletUsingFrequence: {
          ...action.payload.data
        }
      }
    },
    setReportEventSelectedMultiFloorPlan(state, action) {
      console.log(action);
      return {
        ...state,
        selectedMultiFloorPlan: [...action.payload],
      }
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
  }
});

export const {
  apiError,
  getToiletFallSummarySuccess,
  getToiletOTSummarySuccess,
  getToiletArrivalSummarySuccess,
  getToiletUsingAverageTimeSummarySuccess,
  getToiletUsingFrequenceSummarySuccess,
  getBedFallSummarySuccess,
  getBedArrivalSummarySuccess,
  setReportEventSelectedMultiFloorPlan,
  resetState
} = summarySlice.actions

export default summarySlice.reducer;