import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import DeviceSection from "./DeviceSection";
import { useTranslation } from 'react-i18next';
import HistorySection from "./HistorySection";

function Dashboard() {
  const { t } = useTranslation();
  document.title = t('PageMain.DocumentTitle'); 
  const [isEventShown, setEventShown] = useState(true);
  const [isHistoryShown, setHistoryShown] = useState(false);

  return (
    <React.Fragment>
      <div className="page-content page-content-dash">
        <Container fluid className="dash">
          <Row className="col_3_device" >
            <Col className="dash-section" style={{height:isHistoryShown?isEventShown?'calc(60% - 18px)':'calc(100% - 140px)':'calc(100% - 20px)'}} sm={12}>
              <DeviceSection setHistoryShown={()=>{setHistoryShown(!isHistoryShown)}} isHistoryShown={isHistoryShown}/>
            </Col>
            {isHistoryShown?
            <Col className="dash-section" style={{height:isEventShown?'calc(40% - 18px)':'auto'}} sm={12}>
              <HistorySection isEventShown={isEventShown} setEventShown={setEventShown}/>
            </Col>:null}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
