import React from 'react';
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";

const UpdateDountChart = ({ dataColors, xData, yData }) => {    
    var chartDonutupdatingColors = getChartColorsArray(dataColors);
    const series = xData;
    var options = {
        chart: {
            padding: {
                bottom: 200
            }
        },
        dataLabels: {
            enabled: true
        },
        labels: yData,
        legend: {
            position: 'bottom'
        },
        colors: chartDonutupdatingColors
    };
    return (
        <ReactApexChart dir="ltr"
            className="apex-charts"
            series={series}
            options={options}
            type="donut"
            height={360}
        />
    )
};

export default UpdateDountChart;