import { createSlice } from "@reduxjs/toolkit";
import { MONITOR_TYPE, REPORT_PAGE_SIZE, SORT } from "../../common/constants";
import { REPORT_LIST_COL_NAME } from "../../pages/Report/ReportListTable";
import { ALL_EVNET_HANDLING } from "../../Components/Common/DeviceFilter";

export const initialState = {
  eventStatusChangeIDEdit: null,
  events: {},
  error: {},
  messages: {
  },
  report: {
    reportID: null,
    isModifiedReportID: false,
    reportName: "",
    dateRange: null, //  [new Date(), new Date().setMonth(new Date().getMonth() - 1)]
    selectedMultiFloorPlan: null,
    selectedMultiMonitorType: null,
    selectedMultiMonitorStatus: null,
    selectedMultiEventHandling: null,
    eventIDs:[],
    events: {},
    changes: {},
    totalEvents: 0,
    pageNumber: "1",
    pageSize: REPORT_PAGE_SIZE,
    lastUpdated: new Date().getTime(),
    isLoading: false
  },
  savedReportList: {
    data: [],
    totalReports: 0,
    pageNumber: 1,
    pageSize: REPORT_PAGE_SIZE,
    searchText: "",
    sortColumn: REPORT_LIST_COL_NAME.CREATED_AT,
    sortDirection: SORT.DESC,
    lastUpdated: new Date().getTime()
  }
};

const eventSlice = createSlice({
  name: 'Event',
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
    },
    getEventSuccess(state, action) {
      state.events = action.payload.events;
    },
    getEventMessageSuccess(state, action) {
      state.messages[action.payload.eventID] = action.payload.result;
    },
    createEventMessageSuccess(state, action) {
      state.messages[action.payload.eventID] = action.payload.result;
    },
    getEventsReportSuccess(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          ...action.payload.data,
          isLoading: false
        }
      }
    },
    getEventsReportByIDSuccess(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          ...action.payload,
        }
      }
    },
    getEventsReportListSuccess(state, action) {
      console.log(action);
      return {
        ...state,
        savedReportList: {
          ...state.savedReportList,
          ...action.payload.data
        }
      }
    },
    setReportEventDateRange(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          dateRange: [...action.payload],
          isModifiedReportID: true,
          pageNumber: "1",
        }
      }
    },
    setReportEventSelectedMultiFloorPlan(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          selectedMultiFloorPlan: [...action.payload],
          isModifiedReportID: true,
          pageNumber: "1",
        }
      }
    },
    setReportEventSelectedMultiMonitorType(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          selectedMultiMonitorType: [...action.payload],
          isModifiedReportID: true,
          pageNumber: "1",
        }
      }
    },
    setReportEventSelectedMultiMonitorStatus(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          selectedMultiMonitorStatus: [...action.payload],
          isModifiedReportID: true,
          pageNumber: "1",
        }
      }
    },
    setReportEventSelectedEventHandling(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          selectedMultiEventHandling: [...action.payload],
          isModifiedReportID: true,
          pageNumber: "1",
        }
      }
    },
    setReportPage(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          pageNumber: action.payload,
        }
      }
    },
    updateSelectedReportID(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          reportID: action.payload,
          isModifiedReportID: false,
          reportName: action.payload.name,
        }
      }
    },
    saveEventsReportSuccess(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          reportID: action.payload.data,
          isModifiedReportID: false,
          reportName: action.payload.data.name
        }
      }
    },
    updateSavedEventListTableParameters(state, action) {
      console.log(action);
      return {
        ...state,
        savedReportList: {
          ...state.savedReportList,
          ...action.payload
        }
      }
    },
    resetSavedEventListTableParameters(state) {
      return {
        ...state,
        savedReportList: {
          ...initialState.savedReportList,
          lastUpdated: new Date().getTime()
        }
      }
    },
    resetEventTableParameters(state) {
      return {
        ...state,
        report: {
          ...initialState.report,
          lastUpdated: new Date().getTime()
        }
      }
    },
    setSaveReportName(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          reportName: action.payload
        }
      }
    },
    setIsReportLoading(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          isLoading: action.payload
        }
      }
    },
    setEventStatusChangeIDEdit(state, action) {
      console.log(action);
      return {
        ...state,
        eventStatusChangeIDEdit: action.payload
      }
    },
    updateStatusChangeReportInReportSuccess(state, action) {
      let status = state.report.changes[action.payload.statusChangeID];
      
      if (status === null || status === undefined) {
        return state;
      }

      let newStatus = {...status, reportReasonID: action.payload.reason};

      
      let isShow = false;
      state.report.selectedMultiEventHandling.forEach((option) => {
        if (option.value === ALL_EVNET_HANDLING || option.value === action.payload.reason) {
          isShow = true;
        }
      });
      let newEvent = {...state.report.events[status.eventID], isShow: isShow};
      return {
        ...state,
        report: {
          ...state.report,
          changes: {
            ...state.report.changes,
            [action.payload.statusChangeID]: newStatus
          },
          events: {
            ...state.report.events,
            [status.eventID]: newEvent
          }
        }
      }
    },
    resetState: () => initialState
  }
});

export const {
  apiError,
  getEventSuccess,
  getEventMessageSuccess,
  createEventMessageSuccess,
  getEventsReportSuccess,
  getEventsReportListSuccess,
  setReportEventDateRange,
  setReportEventSelectedMultiFloorPlan,
  setReportEventSelectedMultiMonitorType,
  setReportEventSelectedMultiMonitorStatus,
  setReportEventSelectedEventHandling,
  setReportPage,
  getEventsReportByIDSuccess,
  updateSelectedReportID,
  saveEventsReportSuccess,
  updateSavedEventListTableParameters,
  resetSavedEventListTableParameters,
  resetEventTableParameters,
  setSaveReportName,
  setIsReportLoading,
  setEventStatusChangeIDEdit,
  updateStatusChangeReportInReportSuccess,
  resetState,
} = eventSlice.actions

export default eventSlice.reducer;