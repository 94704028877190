import { convertArrayToString, convertEventHandlingToDB, convertLocationToDB, convertMonitorStatusToDB, convertMonitorTypeToDB } from '../common/utils';
import { APIClient } from '../helpers/api_helper';
import { CREATE_EVENT_MESSAGE, DELETE_EVENT_REPORT, GET_ALL_PENDING_EVENT, GET_EVENT, GET_EVENT_MESSAGE, GET_EVENT_REPORT, GET_EVENT_REPORT_EXPORT, GET_EVENT_REPORT_LIST, GET_PENDING_EVENT, POST_EVENT_REPORT_SAVE, POST_EVENT_REPORT_SAVE_AS, REPORT_EVENT } from '../helpers/url_helper';
import { saveAs } from 'file-saver';

const api = new APIClient();

export const getAllPendingEventsService = async () => {
    return await api.get(GET_ALL_PENDING_EVENT);
};

export const getEventService = async eventID => {
    return await api.get(GET_EVENT + '/' + eventID);
};

export const reportStatusChangeService = async (statusChangeID, reason) => {
    return await api.create(REPORT_EVENT, { statusChangeID: statusChangeID, reason: reason });
};

export const createEventMessageService = async (eventID, message) => {
    return await api.create(CREATE_EVENT_MESSAGE, { id: eventID, message: message });
};

export const getEventMessagesService = async eventID => {
    return await api.get(GET_EVENT_MESSAGE + '/' + eventID);
};

export const getEventReportService = async (selectedFloorPlan, selectedType, selectedStatus, selectedEventHandling, dateRange, pageNumber, pageSize) => {
    let data2 = dateRange[1];
    if (isNaN(data2)) {
        data2 = dateRange[0];
    }
    let startDt = new Date(dateRange[0] > data2 ? data2 : dateRange[0]);
    startDt.setHours(0);
    startDt.setMinutes(0);
    startDt.setSeconds(0);
    let endDt = new Date(dateRange[0] > data2 ? dateRange[0] : data2);
    endDt.setDate(endDt.getDate());
    endDt.setHours(23);
    endDt.setMinutes(59);
    endDt.setSeconds(59);
    const query = {
        page: pageNumber,
        pageSize: pageSize,
        location: JSON.stringify(convertLocationToDB(selectedFloorPlan)),
        type: JSON.stringify(convertMonitorTypeToDB(selectedType)),
        status: JSON.stringify(convertMonitorStatusToDB(selectedStatus)),
        eventHandling: JSON.stringify(convertEventHandlingToDB(selectedEventHandling)),
        startDt: Math.trunc(startDt.getTime() / 1000),
        endDt: Math.trunc(endDt.getTime() / 1000)
    };
    console.log(query);
    return await api.get(GET_EVENT_REPORT, query);
};

export const saveEventReportService = async (reportID, selectedFloorPlan, selectedType, selectedStatus, selectedEventHandling, dateRange, reportName) => {
    let data2 = dateRange[1];
    if (isNaN(data2)) {
        data2 = dateRange[0];
    }
    let startDt = new Date(dateRange[0] > data2 ? data2 : dateRange[0]);
    startDt.setHours(0);
    startDt.setMinutes(0);
    startDt.setSeconds(0);
    let endDt = new Date(dateRange[0] > data2 ? dateRange[0] : data2);
    endDt.setDate(endDt.getDate());
    endDt.setHours(23);
    endDt.setMinutes(59);
    endDt.setSeconds(59);
    let data = {
        location: JSON.stringify(convertLocationToDB(selectedFloorPlan)),
        type: JSON.stringify(convertMonitorTypeToDB(selectedType)),
        status: JSON.stringify(convertMonitorStatusToDB(selectedStatus)),
        eventHandling: JSON.stringify(convertEventHandlingToDB(selectedEventHandling)),
        startDt: Math.trunc(startDt.getTime() / 1000),
        endDt: Math.trunc(endDt.getTime() / 1000),
        name: reportName,
        reportID: reportID
    };
    let result = await api.create(POST_EVENT_REPORT_SAVE, data);
    return result;
};

export const exportEventReportService = async (selectedFloorPlan, selectedType, selectedStatus, selectedEventHandling, dateRange, language, fileName) => {
    let data2 = dateRange[1];
    if (isNaN(data2)) {
        data2 = dateRange[0];
    }
    let startDt = new Date(dateRange[0] > data2 ? data2 : dateRange[0]);
    startDt.setHours(0);
    startDt.setMinutes(0);
    startDt.setSeconds(0);
    let endDt = new Date(dateRange[0] > data2 ? dateRange[0] : data2);
    endDt.setDate(endDt.getDate());
    endDt.setHours(23);
    endDt.setMinutes(59);
    endDt.setSeconds(59);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let response = await api.get(GET_EVENT_REPORT_EXPORT, {
        responseType: 'blob',
        params: {
            location: JSON.stringify(convertLocationToDB(selectedFloorPlan)),
            type: JSON.stringify(convertMonitorTypeToDB(selectedType)),
            status: JSON.stringify(convertMonitorStatusToDB(selectedStatus)),
            eventHandling: JSON.stringify(convertEventHandlingToDB(selectedEventHandling)),
            startDt: Math.trunc(startDt.getTime() / 1000),
            endDt: Math.trunc(endDt.getTime() / 1000),
            locationString: JSON.stringify(convertArrayToString(selectedFloorPlan)),
            typeString: JSON.stringify(convertArrayToString(selectedType)),
            statusString: JSON.stringify(convertArrayToString(selectedStatus)),
            eventHandlingString: convertArrayToString(selectedEventHandling),
            dateRangeString: startDt + ' - ' + endDt,
            language: language,
            fileName: fileName,
            timezone: timezone
        }
    });

    const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    saveAs(blob, fileName + '.xlsx');
};

export const getSavedReportListService = async (searchText, sortColumn, sortDirection, pageNumber, pageSize) => {
    console.log('getSavedReportListService pageNumber: ' + pageNumber);
    let result = await api.get(GET_EVENT_REPORT_LIST, { search: searchText, sortColumn: sortColumn, sortDirection: sortDirection, page: pageNumber, pageSize: pageSize });
    console.log('result');
    console.log(result);
    return result;
};

export const deleteSavedReportService = async reportID => {
    let result = await api.delete(DELETE_EVENT_REPORT + '/' + reportID);
    console.log('result');
    console.log(result);
    return result;
};

export const getEventReportByReportIDService = async reportID => {
    return await api.get(GET_EVENT_REPORT + '/' + reportID);
};

export const exportEventReportByReportIDService = async (reportID, language, fileName) => {
    console.log(GET_EVENT_REPORT_EXPORT + '/' + reportID);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let response = await api.get(GET_EVENT_REPORT_EXPORT + '/' + reportID, { responseType: 'blob', params: { language: language, fileName: fileName, timezone: timezone } });

    const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    saveAs(blob, fileName + '.xlsx');
};
